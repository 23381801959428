import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const SET_SEARCH_KEY = 'modules/ui/attendees/SET_SEARCH_KEY';
const SET_SELECTED_ATTENDEE_ID = 'modules/ui/attendees/SET_SELECTED_ATTENDEE_ID';

const initialState = fromJS({
  searchKey: '',
  selectedAttendeeId: null,
});

const reducer = handleActions({
  [SET_SEARCH_KEY]: (state, action = {}) => state.set('searchKey', action.payload),
  [SET_SELECTED_ATTENDEE_ID]: (state, action = {}) => state.set('selectedAttendeeId', action.payload),
}, initialState);

export const setSearchKeyAction = createAction(SET_SEARCH_KEY, (key) => (key));
export const setSelectedAttendeeIdAction = createAction(SET_SELECTED_ATTENDEE_ID, (id) => (id));

export default reducer;
