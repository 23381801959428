import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const LoadingMask = ({ text }) => (
  <S.LoadingMask>
    <S.Circle />
    <S.Text>{text}</S.Text>
  </S.LoadingMask>
);

LoadingMask.propTypes = {
  text: PropTypes.string,
};

LoadingMask.defaultProps = {
  text: 'Loading...',
};

export default LoadingMask;
