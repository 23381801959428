import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const SET_FORM = 'modules/ui/login/SET_FORM';
const SET_LOADING = 'modules/ui/login/SET_LOADING';

const initialState = fromJS({
  loading: false,
  form: {},
});

const reducer = handleActions({
  [SET_FORM]: (state, action = {}) => state.set('form', fromJS(action.payload)),
  [SET_LOADING]: (state, action = {}) => state.set('loading', action.payload),
}, initialState);

export const setFormAction = createAction(SET_FORM, (form) => (form));
export const setLoadingAction = createAction(SET_LOADING, (loading) => (loading));

export default reducer;
