import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_AUTH = 'modules/data/orders/SET_AUTH';

const initialState = fromJS({});

const reducer = handleActions({
  [SET_AUTH]: (state, action = {}) => fromJS(action.payload || []),
}, initialState);

export const setAuthAction = createAction(SET_AUTH, (auth) => (auth));

export const authenticateAsync = (data) => {
  return async (dispatch, getState, { api }) => {
    let resp = await api.post('/api-token-auth/', data);
    resp = _.get(resp, 'data');
    dispatch(setAuthAction(resp));

    return resp;
  };
};

export default reducer;
