import _ from 'lodash';

export const getConferencesColumns = (conferences) => {
  const list = _.orderBy(conferences, ['created'], ['desc']);
  return _.map(list, ({ conferenceSlug: conference }) => {
    return {
      key: conference,
      title: _.startCase(conference),
    };
  });
};

export function arrayToObject(arr, key = 'id') {
  const obj = {};
  arr.forEach((i) => Object.assign(obj, { [i[key]]: i }));
  return obj;
}
