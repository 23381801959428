import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_ADDITIONAL_ORDERS = 'modules/data/additional-orders/SET_ADDITIONAL_ORDERS';
const ADD_ADDITIONAL_ORDER = 'modules/data/additional-orders/ADD_ADDITIONAL_ORDER';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_ADDITIONAL_ORDERS]: (state, action = {}) => fromJS(action.payload || []),
  [ADD_ADDITIONAL_ORDER]: (state, action = {}) => fromJS([...state, action.payload || []]),
}, initialState);

export const setAdditionalOrdersAction = createAction(SET_ADDITIONAL_ORDERS, (orders) => (orders));
export const addAdditionalOrderAction = createAction(ADD_ADDITIONAL_ORDER, (order) => (order));

export const getAdditionalOrdersAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/internal/additional_orders/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setAdditionalOrdersAction(resp));

    return resp;
  };
};

export const createFreeAdditionalOrder = (data) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.post(
      '/internal/additional_orders/free_additional_order/',
      data,
    );

    resp = _.get(resp, 'data');
    dispatch(addAdditionalOrderAction(resp));

    return resp;
  };
}

export default reducer;
