import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const SET_EDIT = 'modules/ui/company-details/SET_EDIT';
const SET_FORM = 'modules/ui/company-details/SET_FORM';
const SET_LOADING = 'modules/ui/company-details/SET_LOADING';

const initialState = fromJS({
  edit: false,
  loading: false,
  form: {},
});

const reducer = handleActions({
  [SET_EDIT]: (state, action = {}) => state.set('edit', action.payload),
  [SET_FORM]: (state, action = {}) => state.set('form', fromJS(action.payload)),
  [SET_LOADING]: (state, action = {}) => state.set('loading', action.payload),
}, initialState);

export const setEditAction = createAction(SET_EDIT, (edit) => (edit));
export const setFormAction = createAction(SET_FORM, (form) => (form));
export const setLoadingAction = createAction(SET_LOADING, (loading) => (loading));

export default reducer;
