import _ from 'lodash';

const defaultRender = (value) => value;

const getHeader = (columns) => {
  const row = _.reduce(columns,
    (sum, { title }) => `${sum}"${title}",`,
    '');

  return `${row}\r\n`;
};

const getRow = (data, columns) => {
  const values = _.map(columns, ({ key, render = defaultRender }) => {
    return `"${render(_.get(data, key))}"`;
  });
  return _.join(values, ',');
};

const getContent = (data, columns) => {
  return _.reduce(data,
    (sum, row) => `${sum}${getRow(row, columns)}\r\n`,
    '');
};

const convertToCSV = (data, columns) => {
  const header = getHeader(columns);
  const rows = getContent(data, columns);
  return `${header}${rows}`;
};

export default convertToCSV;
