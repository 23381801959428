import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_MAIL_EVENTS = 'modules/data/mail-events/SET_MAIL_EVENTS';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_MAIL_EVENTS]: (state, action = {}) => fromJS(action.payload || []),
}, initialState);

export const setMailEventsAction = createAction(SET_MAIL_EVENTS, (data) => (data));

export const getMailEventsAsync = (transmissionId) => {
  return async (dispatch, getState, { apiGateway }) => {
    let resp = await apiGateway.get('/api/mail/events/', { params: { transmissionId } });
    resp = _.get(resp, 'data');
    dispatch(setMailEventsAction(resp));

    return resp;
  };
};

export default reducer;
