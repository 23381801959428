import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import authDataReducer from 'modules/data/auth';
import conferencesDataReducer from 'modules/data/conferences';
import lookupsDataReducer from 'modules/data/lookups';
import ordersDataReducer from 'modules/data/orders';
import conferenceAttendeesReducer from 'modules/data/attendees';
import featuredCompaniesReducer from 'modules/data/featured-companies';
import companyAttendeesReducer from 'modules/data/company-attendees';
import exhibitorsDataReducer from 'modules/data/exhibitors';
import additionalOrdersDataReducer from 'modules/data/additional-orders';
import artworksDataReducer from 'modules/data/artworks';
import primaryContactsDataReducer from 'modules/data/primary-contacts';
import ledgersDataReducer from 'modules/data/ledgers';
import mailTemplatesDataReducer from 'modules/data/mail-templates';
import mailAutomationsDataReducer from 'modules/data/mail-automations';
import mailReportsDataReducer from 'modules/data/mail-reports';
import mailEventsDataReducer from 'modules/data/mail-events';
import proofOfPaymentsDataReducer from 'modules/data/proof-of-payments';
import cartsDataReducer from 'modules/data/carts';
import diamondInquiriesDataReducer from 'modules/data/diamond-inquiries';
import sponsorshipItemsDataReducer from 'modules/data/sponsorship-items';
import waitListDataReducer from 'modules/data/wait-list';
import boothsDataReducer from 'modules/data/booths';
import crmDataReducer from 'modules/data/crm';
import crmNotesDataReducer from 'modules/data/crm-notes';
import registrationsDataReducer from 'modules/data/registrations';
import boothTransferRequestsDataReducer from 'modules/data/booth-transfer-requests';

import featuredCompaniesUiReducer from 'modules/ui/featured-companies';
import featuredCompanyDetailsUiReducer from 'modules/ui/featured-company-details';
import conferenceAttendeesUiReducer from 'modules/ui/attendees';
import ordersUiReducer from 'modules/ui/orders';
import loginUiReducer from 'modules/ui/login';
import invoicesUiReducer from 'modules/ui/invoices';
import artworksUiReducer from 'modules/ui/artworks';
import companyDetailsUiReducer from 'modules/ui/company-details';
import contactsUiReducer from 'modules/ui/contacts';
import notesUiReducer from 'modules/ui/notes';
import boothsUiReducer from 'modules/ui/booths';
import mailTemplatesUiReducer from 'modules/ui/mail-templates';
import mailAutomationsUiReducer from 'modules/ui/mail-automations';
import mailReportsUiReducer from 'modules/ui/mail-reports';
import mailUiReducer from 'modules/ui/mail';
import crmUiReducer from 'modules/ui/crm';
import orderSponsorshipsUiReducer from 'modules/ui/order-sponsorships';
import boothTransferRequestsUiReducer from 'modules/ui/booth-transfer-requests';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authDataReducer,
    conferences: conferencesDataReducer,
    lookups: lookupsDataReducer,
    orders: ordersDataReducer,
    conferenceAttendees: conferenceAttendeesReducer,
    featuredCompanies: featuredCompaniesReducer,
    companyAttendees: companyAttendeesReducer,
    booths: boothsDataReducer,
    exhibitors: exhibitorsDataReducer,
    additionalOrders: additionalOrdersDataReducer,
    artworks: artworksDataReducer,
    primaryContacts: primaryContactsDataReducer,
    ledgers: ledgersDataReducer,
    mailTemplates: mailTemplatesDataReducer,
    mailAutomations: mailAutomationsDataReducer,
    mailReports: mailReportsDataReducer,
    mailEvents: mailEventsDataReducer,
    proofOfPayments: proofOfPaymentsDataReducer,
    login: loginUiReducer,
    carts: cartsDataReducer,
    diamondInquiries: diamondInquiriesDataReducer,
    sponsorshipItems: sponsorshipItemsDataReducer,
    waitList: waitListDataReducer,
    crm: crmDataReducer,
    crmNotes: crmNotesDataReducer,
    registrations: registrationsDataReducer,
    
    conferenceAttendeesUi: conferenceAttendeesUiReducer,
    featuredCompaniesUi: featuredCompaniesUiReducer,
    featuredCompanyDetailsUi: featuredCompanyDetailsUiReducer,
    ordersUi: ordersUiReducer,
    boothsUi: boothsUiReducer,
    invoicesUi: invoicesUiReducer,
    artworksUi: artworksUiReducer,
    companyDetailsUi: companyDetailsUiReducer,
    contactsUi: contactsUiReducer,
    notesUi: notesUiReducer,
    orderSponsorshipsUi: orderSponsorshipsUiReducer,
    mailTemplatesUi: mailTemplatesUiReducer,
    mailAutomationsUi: mailAutomationsUiReducer,
    mailReportsUi: mailReportsUiReducer,
    mailUi: mailUiReducer,
    crmUi: crmUiReducer,
    boothTransferRequests: boothTransferRequestsDataReducer,
    boothTransferRequestsUi: boothTransferRequestsUiReducer,
  });
