import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import {
  DefaultFilters,
} from 'common/constants';

const SET_SEARCH_KEY = 'modules/ui/booths/SET_SEARCH_KEY';
const SET_FILTERS = 'modules/ui/booths/SET_FILTERS';
const SET_FORM = 'modules/ui/booths/SET_FORM';

const initialState = fromJS({
  searchKey: '',
  filters: DefaultFilters,
  form: {},
});

const reducer = handleActions({
  [SET_SEARCH_KEY]: (state, action = {}) => state.set('searchKey', action.payload),
  [SET_FILTERS]: (state, action = {}) => state.set('filters', fromJS(action.payload)),
  [SET_FORM]: (state, action = {}) => state.set('form', fromJS(action.payload)),
}, initialState);

export const setSearchKeyAction = createAction(SET_SEARCH_KEY, (key) => (key));
export const setFiltersAction = createAction(SET_FILTERS, (filters) => (filters));
export const setFormAction = createAction(SET_FORM, (form) => (form));

export default reducer;
