import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_SPONSORSHIP_ITEMS = 'modules/data/sponsorship-items/SET_SPONSORSHIP_ITEMS';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_SPONSORSHIP_ITEMS]: (state, action = {}) => fromJS(action.payload || []),
}, initialState);

export const setSponsorshipItemsAction = createAction(SET_SPONSORSHIP_ITEMS, (data) => (data));

export const getSponsorshipItemsAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/sponsorship_items/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setSponsorshipItemsAction(resp));

    return resp;
  };
};

export default reducer;
