import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

import { parseFormData } from 'common/utils';

const SET_ORDERS = 'modules/data/orders/SET_ORDERS';
const UPDATE_ORDER = 'modules/data/orders/UPDATE_ORDER';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_ORDERS]: (state, action = {}) => fromJS(action.payload || []),
  [UPDATE_ORDER]: (state, action = {}) => {
    const { key, data = {} } = action.payload || {};
    return state.update(state.findIndex((item) => item.get(key) === _.get(data, key)),
      (item) => item.merge(data));
  },
}, initialState);

export const setOrdersAction = createAction(SET_ORDERS, (orders) => (orders));
export const updateOrderAction = createAction(UPDATE_ORDER, (key, data) => ({ key, data }));

export const getOrdersAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/internal/orders/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setOrdersAction(resp));

    return resp;
  };
};

export const updateOrderAsync = (id, data, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(`/orders/${id}/`, data, { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(updateOrderAction('id', resp));

    return resp;
  };
};

export function reassignBoothAsync(id, data, conference) {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(`/orders/${id}/reassign_booth/`, data, { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(updateOrderAction('id', resp));

    return resp;
  };
}

export function cancelOrderAsync(id, conference) {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(`/orders/${id}/cancel_order/`, {}, { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(updateOrderAction('id', resp));

    return resp;
  };
}

export const updateSponsorshipsAsync = (id, data, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(`/internal/orders/${id}/`,
      parseFormData(data), { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(updateOrderAction('id', resp));

    return resp;
  };
};

export default reducer;
