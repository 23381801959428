export default {
  GRAY: '#C1CBD8',
  GREEN: '#AADE75',
  DARKGREEN: '#4eca47',
  YELLOW: '#FDD600',
  RED: '#FF5469',
  BLUE: '#47AEFF',
  PURPLE: '#9C87FF',
  ORANGE: '#FE8A1C',
  PINK: '#FF8DE4',
  TURQUOISE: '#00CEC9',
};
