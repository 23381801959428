import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

import Countries from 'common/countries';

const SET_AREAS_OF_SPECIALTIES = 'modules/data/lookups/SET_AREAS_OF_SPECIALTIES';
const SET_BOOTH_TYPES = 'modules/data/lookups/SET_BOOTH_TYPES';
const SET_EXHIBITOR_TYPES = 'modules/data/lookups/SET_EXHIBITOR_TYPES';
const SET_SPONSORSHIP_TYPES = 'modules/data/lookups/SET_SPONSORSHIP_TYPES';
const SET_COMPANY_TYPES = 'modules/data/lookups/SET_COMPANY_TYPES';
const SET_COMPANY_AREA_OF_SPECIALTIES = 'modules/data/lookups/SET_COMPANY_AREA_OF_SPECIALTIES';

const initialState = fromJS({
  areasOfSpecialties: [],
  boothTypes: [],
  exhibitorTypes: [],
  sponsorshipTypes: [],
  countries: Countries,
  companyTypes: [],
  companyAreaOfSpecialties: []
});

const reducer = handleActions({
  [SET_AREAS_OF_SPECIALTIES]: (state, action = {}) => state.set('areasOfSpecialties',
    fromJS(action.payload || [])),
  [SET_BOOTH_TYPES]: (state, action = {}) => state.set('boothTypes', fromJS(action.payload || [])),
  [SET_EXHIBITOR_TYPES]: (state, action = {}) => state.set('exhibitorTypes', fromJS(action.payload || [])),
  [SET_SPONSORSHIP_TYPES]: (state, action = {}) => state.set('sponsorshipTypes', fromJS(action.payload || [])),
  [SET_COMPANY_TYPES]: (state, action = {}) => state.set('companyTypes', fromJS(action.payload || [])),
  [SET_COMPANY_AREA_OF_SPECIALTIES]: (state, action = {}) => state.set('companyAreaOfSpecialties', fromJS(action.payload || [])),
}, initialState);

export const setAreasOfSpecialtiesAction = createAction(SET_AREAS_OF_SPECIALTIES,
  (constants) => (constants));
export const setBoothTypesAction = createAction(SET_BOOTH_TYPES,
  (constants) => (constants));
export const setExhibitorTypesAction = createAction(SET_EXHIBITOR_TYPES,
  (constants) => (constants));
export const setSponsorshipTypesAction = createAction(SET_SPONSORSHIP_TYPES,
  (constants) => (constants));
export const setCompanyTypesAction = createAction(SET_COMPANY_TYPES,
  (constants) => (constants));
export const setCompanyAreaOfSpecialtiesAction = createAction(SET_COMPANY_AREA_OF_SPECIALTIES,
  (constants) => (constants));

export const getAreasOfSpecialtiesAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/areas_of_specialty/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setAreasOfSpecialtiesAction(resp));

    return resp;
  };
};

export const getBoothTypesAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/booth_types/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setBoothTypesAction(resp));

    return resp;
  };
};

export const getExhibitorTypesAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/exhibitor_types/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setExhibitorTypesAction(resp));

    return resp;
  };
};

export const getSponsorshipTypesAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/sponsorship_types/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setSponsorshipTypesAction(resp));

    return resp;
  };
};

export const getCompanyTypesAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/company-list/types/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setCompanyTypesAction(resp));

    return resp;
  };
};
export const getCompanyAreaOfSpecialtiesAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/company-list/area-of-specialties/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setCompanyAreaOfSpecialtiesAction(resp));

    return resp;
  };
};


export function getLookupsAsync(conference) {
  return (dispatch) => {
    return Promise.all([
      dispatch(getAreasOfSpecialtiesAsync(conference)),
      dispatch(getBoothTypesAsync(conference)),
      dispatch(getExhibitorTypesAsync(conference)),
      dispatch(getSponsorshipTypesAsync(conference)),
      dispatch(getCompanyTypesAsync(conference)),
      dispatch(getCompanyAreaOfSpecialtiesAsync(conference)),
    ]);
  };
}

export default reducer;
