import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const SET_SEARCH_KEY = 'modules/ui/mail-reports/SET_SEARCH_KEY';

const initialState = fromJS({
  searchKey: '',
});

const reducer = handleActions({
  [SET_SEARCH_KEY]: (state, action = {}) => state.set('searchKey', action.payload),
}, initialState);

export const setSearchKeyAction = createAction(SET_SEARCH_KEY, (key) => (key));

export default reducer;
