import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_CARTS = 'modules/data/carts/SET_CARTS';
const ADD_CART = 'modules/data/carts/ADD_CART';
const REMOVE_CART = 'modules/data/carts/REMOVE_CART';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_CARTS]: (state, action = {}) => fromJS(action.payload || []),
  [ADD_CART]: (state, action = {}) => state.push(fromJS(action.payload || {})),
  [REMOVE_CART]: (state, action = {}) => {
    const { key, value } = action.payload || {};
    return state.delete(state.findIndex((item) => item.get(key) === value));
  },
}, initialState);

export const setCartsAction = createAction(SET_CARTS, (data) => (data));
export const addCartAction = createAction(ADD_CART, (data) => (data));
export const removeCartAction = createAction(REMOVE_CART, (key, value) => ({ key, value }));

export const getCartsAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/internal/pending_carts/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setCartsAction(resp));

    return resp;
  };
};

export function releaseCartAsync(id, conference) {
  return async (dispatch, getState, { apiAuth }) => {
    const resp = await apiAuth.patch(`/carts/${id}/release_items/`, {}, { params: { conference } });
    dispatch(removeCartAction('id', id));

    return resp;
  };
}

export function generateCartAsync(data, conference) {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.post('/internal/generate_cart/', data, { params: { conference } });
    resp = _.get(resp, 'data') || {};
    resp = {
      id: resp.cart || '',
      lineitem_set: [{
        item_type: 'booth',
        metadata: data,
      }],
    };
    dispatch(addCartAction(resp));

    return resp;
  };
}

export default reducer;
