import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_PRIMARY_CONTACTS = 'modules/data/primary-contacts/SET_PRIMARY_CONTACTS';
const UPDATE_CONTACT = 'modules/data/primary-contacts/UPDATE_CONTACT';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_PRIMARY_CONTACTS]: (state, action = {}) => fromJS(action.payload || []),
  [UPDATE_CONTACT]: (state, action = {}) => {
    const { key, data = {} } = action.payload || {};
    return state.update(state.findIndex((item) => item.get(key) === _.get(data, key)),
      (item) => item.merge(data));
  },
}, initialState);

export const setPrimaryContactsAction = createAction(SET_PRIMARY_CONTACTS,
  (contacts) => (contacts));
export const updatePrimaryContactAction = createAction(UPDATE_CONTACT,
  (key, data) => ({ key, data }));

export const getPrimaryContactsAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/internal/primary_contacts/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setPrimaryContactsAction(resp));

    return resp;
  };
};

export const updatePrimaryContactAsync = (id, data, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(`/internal/primary_contacts/${id}/`, data, { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(updatePrimaryContactAction('id', resp));

    return resp;
  };
};

export default reducer;
