import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_EXHIBITORS = 'modules/data/exhibitors/SET_EXHIBITORS';
const UPDATE_EXHIBITOR = 'modules/data/exhibitors/UPDATE_EXHIBITOR';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_EXHIBITORS]: (state, action = {}) => fromJS(action.payload || []),
  [UPDATE_EXHIBITOR]: (state, action = {}) => {
    const { key, data = {} } = action.payload || {};
    return state.update(state.findIndex((item) => item.get(key) === _.get(data, key)),
      (item) => item.merge(data));
  },
}, initialState);

export const setExhibitorsAction = createAction(SET_EXHIBITORS, (exhibitors) => (exhibitors));
export const updateExhibitorAction = createAction(UPDATE_EXHIBITOR, (key, data) => ({ key, data }));

export const getExhibitorsAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/internal/exhibitors/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setExhibitorsAction(resp));

    return resp;
  };
};

export const updateExhibitorAsync = (id, data, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(`/internal/exhibitors/${id}/`, data, { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(updateExhibitorAction('id', resp));

    return resp;
  };
};

export default reducer;
