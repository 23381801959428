import { fromJS } from 'immutable';
import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';
import { showNotification } from 'common/utils';
import { message } from 'antd';
import _ from 'lodash';

const SET_FEATURED_COMPANIES = 'modules/data/featured_companies/SET_FEATURED_COMPANIES';
const UPDATE_COMPANY = 'modules/data/featured_companies/UPDATE_COMPANY';

const initialState = fromJS([]);

const defaultConference = localStorage.getItem('conferenceId');

const reducer = handleActions({
  [SET_FEATURED_COMPANIES]: (state, action = {}) => fromJS(action.payload || []),
  [UPDATE_COMPANY]: (state, action = {}) => {
    const { key, data = {} } = action.payload || {};
    return state.update(state.findIndex((item) => item.get(key) === _.get(data, key)),
      (item) => item.merge(data));
  },
}, initialState);

export const setFeaturedCompaniesAction = createAction(SET_FEATURED_COMPANIES, (companies) => companies);
export const updateCompanyAction = createAction(UPDATE_COMPANY, (key, data) => ({ key, data }));

export const getFeaturedCompaniesAsync = conference => {
  return async (dispatch, getState, { apiAuth }) => {
    let response = await apiAuth.get('/companies/companies', { params: { conference }});
    response = _.get(response, 'data');
    dispatch(setFeaturedCompaniesAction(response));
    return response;
  }
}

export const updateCompanyAsync = (id, data, conference = defaultConference) => {
  return async (dispatch, getState, { apiAuth }) => {
    try {
      let response = await apiAuth.patch(`/companies/companies/${id}/`, {
        ...data,
        area_of_specialties: data.area_of_specialties || [],
        traffics: data.traffics || [],
        geos: data.geos || [],
        published: data.published ?? false,
      }, { params: { conference }});
      response = _.get(response, 'data');
      dispatch(updateCompanyAction('id', response));
      message.success('Succesfully updated.');
      return response;
    } catch (err) {
      console.error(err);
      showNotification('Company update failed. Try again later.');
    }
  };
};

export const publishCompanyAsync = (id, { published, status }, conference = defaultConference) => {
  return async (dispatch, getState, { apiAuth }) => {
    try {
      let response = await apiAuth.patch(`/companies/companies/${id}/`, {
        published: published ?? false,
        status,
      }, { params: { conference }});
      response = _.get(response, 'data');
      dispatch(updateCompanyAction('id', response));
      return response;
    } catch (err) {
      console.error(err);
      showNotification('Publish company failed. Try again later.');
    }
  };
};

const getUploadUrlAsync = async (apiAuth, companyId, file_name, file_size, file_type, conference = defaultConference) => {
  const response = await apiAuth.post(`/companies/companies/${companyId}/get_upload_url/`, {
    file_name,
    file_size,
    content_type: file_type,
  }, { params: { conference }});

  return response;
};

export const uploadCompanyLogoAsync = (companyId, file, conference = defaultConference) => {
  return async (dispatch, getState, { apiAuth }) => {
    try {
      let uploadResponse = await getUploadUrlAsync(apiAuth, companyId, file.name, file.size, file.type);
      const uploadUrl = _.get(uploadResponse, 'data.upload_url', '');
      let confirmedUploadResponse = await axios.put(uploadUrl, file).then(async () => {
        return await apiAuth.post(`/companies/companies/${companyId}/confirm_url/?conference=${conference}`);
      })

      confirmedUploadResponse = _.get(confirmedUploadResponse, 'data');
      console.log('Logo upload:', confirmedUploadResponse);
      return confirmedUploadResponse;

    } catch (err) {
      showNotification('Upload company logo failed.');
    }
  };
};


export default reducer;