import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const SET_SEARCH_KEY = 'modules/ui/featured-companies/SET_SEARCH_KEY';
const SET_SELECTED_ROW_KEYS = 'modules/ui/featured-companies/SET_SELECTED_ROW_KEYS';

const initialState = fromJS({
  searchKey: '',
  selectedRowKeys: [],
});

const reducer = handleActions({
  [SET_SEARCH_KEY]: (state, action = {}) => state.set('searchKey', action.payload),
  [SET_SELECTED_ROW_KEYS]: (state, action = {}) => state.set('selectedRowKeys',
    fromJS(action.payload)),
}, initialState);

export const setSearchKeyAction = createAction(SET_SEARCH_KEY, (key) => (key));
export const setSelectedRowKeysAction = createAction(SET_SELECTED_ROW_KEYS, (keys) => (keys));

export default reducer;
