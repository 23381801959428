import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';

import App from 'containers/App/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import GlobalStyle from 'themes/global';
import 'react-quill/dist/quill.snow.css';

import configureStore, { history } from './configureStore';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/not-found" component={NotFoundPage} />
        <Route path="/" component={App} />
        <Redirect to="/not-found" />
      </Switch>
      <GlobalStyle />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
