import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_REGISTRATION = 'modules/data/registrations/SET_REGISTRATION';
const UPDATE_REGISTRATION = 'modules/data/registrations/UPDATE_REGISTRATION';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_REGISTRATION]: (state, action = {}) => state.push(fromJS(action.payload || [])),
  [UPDATE_REGISTRATION]: (state, action = {}) => {
    const { key, data = {} } = action.payload || {};
    return state.update(state.findIndex((item) => item.get(key) === _.get(data, key)),
      (item) => item.merge(data));
  },
}, initialState);

export const setRegistrationAction = createAction(SET_REGISTRATION,
  (registration) => (registration));
export const updateRegistrationAction = createAction(UPDATE_REGISTRATION,
  (key, data) => ({ key, data }));

const getRegistrationAsync = (slug, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get(`/internal/registrations/${slug}/`, { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setRegistrationAction(resp));

    return resp;
  };
};

export const getRegistrationsAsync = (slugs, conference) => {
  return (dispatch) => {
    return Promise.all(_.map(slugs,
      (slug) => dispatch(getRegistrationAsync(slug, conference))));
  };
};

export function markPaidAsync(slug, conference) {
  return async (dispatch, getState, { apiAuth }) => {
    const resp = await apiAuth.post(`/internal/registrations/${slug}/mark_as_paid/`, {}, { params: { conference } });
    dispatch(updateRegistrationAction('slug', { slug,
      receipt_paid: true,
    }));

    return resp;
  };
}

export function markUnpaidAsync(slug, conference) {
  return async (dispatch, getState, { apiAuth }) => {
    const resp = await apiAuth.delete(`/internal/registrations/${slug}/mark_as_paid/`, { params: { conference } });
    dispatch(updateRegistrationAction('slug', { slug,
      receipt_paid: false,
    }));

    return resp;
  };
}

export default reducer;
