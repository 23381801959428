import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_MAIL_TEMPLATES = 'modules/data/mail-templates/SET_MAIL_TEMPLATES';
const ADD_MAIL_TEMPLATE = 'modules/data/mail-templates/ADD_MAIL_TEMPLATE';
const UPDATE_MAIL_TEMPLATE = 'modules/data/mail-templates/UPDATE_MAIL_TEMPLATE';
const REMOVE_MAIL_TEMPLATE = 'modules/data/mail-templates/REMOVE_MAIL_TEMPLATE';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_MAIL_TEMPLATES]: (state, action = {}) => fromJS(action.payload || []),
  [ADD_MAIL_TEMPLATE]: (state, action = {}) => state.push(fromJS(action.payload || {})),
  [UPDATE_MAIL_TEMPLATE]: (state, action = {}) => {
    const { key, data = {} } = action.payload || {};
    return state.update(state.findIndex((item) => item.get(key) === _.get(data, key)),
      (item) => item.merge(data));
  },
  [REMOVE_MAIL_TEMPLATE]: (state, action = {}) => {
    const { key, value } = action.payload || {};
    return state.delete(state.findIndex((item) => item.get(key) === value));
  },
}, initialState);

export const setMailTemplatesAction = createAction(SET_MAIL_TEMPLATES, (data) => (data));
export const addMailTemplateAction = createAction(ADD_MAIL_TEMPLATE, (data) => (data));
export const updateMailTemplateAction = createAction(UPDATE_MAIL_TEMPLATE,
  (key, data) => ({ key, data }));
export const removeMailTemplateAction = createAction(REMOVE_MAIL_TEMPLATE,
  (key, value) => ({ key, value }));

export const getMailTemplatesAsync = (conference) => {
  return async (dispatch, getState, { apiGateway }) => {
    let resp = await apiGateway.get('/api/mail/templates/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setMailTemplatesAction(resp));

    return resp;
  };
};

export const addMailTemplateAsync = (data) => {
  return async (dispatch, getState, { apiGateway }) => {
    let resp = await apiGateway.post('/api/mail/templates/', data);
    resp = _.get(resp, 'data');
    dispatch(addMailTemplateAction(resp));

    return resp;
  };
};

export const updateMailTemplateAsync = (id, data) => {
  return async (dispatch, getState, { apiGateway }) => {
    let resp = await apiGateway.put(`/api/mail/templates/${id}/`, data);
    resp = _.get(resp, 'data');
    dispatch(updateMailTemplateAction('id', resp));

    return resp;
  };
};

export const removeMailTemplateAsync = (id) => {
  return async (dispatch, getState, { apiGateway }) => {
    const resp = await apiGateway.delete(`/api/mail/templates/${id}/`);
    dispatch(removeMailTemplateAction('id', id));

    return resp;
  };
};

export default reducer;
