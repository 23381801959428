import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_BOOTH_TRANSFER_REQUESTS =
  'modules/data/booth-transfer-requests/SET_BOOTH_TRANSFER_REQUESTS';
const SET_BOOTH_TRANSFER_REQUEST_DETAIL =
  'modules/data/booth-transfer-requests/SET_BOOTH_TRANSFER_REQUEST_DETAIL';
const UPDATE_BOOTH_TRANSFER_REQUEST =
  'modules/data/booth-transfer-requests/UPDATE_BOOTH_TRANSFER_REQUEST';

const initialState = fromJS({
  list: [],
  detail: {},
});

const reducer = handleActions(
  {
    [SET_BOOTH_TRANSFER_REQUESTS]: (state, action = {}) =>
      state.set('list', fromJS(action.payload || [])),
    [SET_BOOTH_TRANSFER_REQUEST_DETAIL]: (state, action = {}) =>
      state.set('detail', fromJS(action.payload || {})),
    [UPDATE_BOOTH_TRANSFER_REQUEST]: (state, action = {}) => {
      const { key, data = {} } = action.payload || {};
      const list = state.get('list');
      const itemIndex = list.findIndex(
        (item) => item.get(key) === _.get(data, key),
      );
      return state.set(
        'list',
        fromJS(
          list.map((item, index) => {
            if (index === itemIndex) {
              return data;
            }
            return item;
          }),
        ),
      );
    },
  },
  initialState,
);

export const setBoothTransferRequestsAction = createAction(
  SET_BOOTH_TRANSFER_REQUESTS,
  (data) => data,
);
export const setBoothTransferRequestDetailAction = createAction(
  SET_BOOTH_TRANSFER_REQUEST_DETAIL,
  (data) => data,
);
export const updateBoothTransferRequestAction = createAction(
  UPDATE_BOOTH_TRANSFER_REQUEST,
  (key, data) => ({ key, data }),
);

export const getBoothTransferRequestsAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/booth_transfer_requests/', {
      params: { conference },
    });
    resp = _.get(resp, 'data');
    dispatch(setBoothTransferRequestsAction(resp));

    return resp;
  };
};

export const getBoothTransferRequestDetailAsync = (id, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get(`/booth_transfer_requests/${id}`, {
      params: { conference },
    });
    resp = _.get(resp, 'data');
    dispatch(setBoothTransferRequestDetailAction(resp));

    return resp;
  };
};

export const approveBoothTransferRequestAsync = (id, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(
      `/booth_transfer_requests/${id}/approve/`,
      null,
      {
        params: { conference },
      },
    );
    resp = _.get(resp, 'data');
    dispatch(setBoothTransferRequestDetailAction(resp));
    dispatch(updateBoothTransferRequestAction('id', resp));

    return resp;
  };
};

export const rejectBoothTransferRequestAsync = (id, conference, data) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(
      `/booth_transfer_requests/${id}/reject/`,
      data,
      {
        params: { conference },
      },
    );

    resp = _.get(resp, 'data');
    dispatch(setBoothTransferRequestDetailAction(resp));
    dispatch(updateBoothTransferRequestAction('id', resp));

    return resp;
  };
};

export default reducer;
