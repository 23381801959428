import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

import { arrayToObject } from 'common/helpers';

export const ATTENDEE_LIMIT = 20;

const SET_CONFERENCE_ATTENDEES = 'modules/data/attendees/SET_CONFERENCE_ATTENDEES';
const UPDATE_CONFERENCE_ATTENDEE = 'modules/data/attendees/UPDATE_CONFERENCE_ATTENDEE';
const SET_TICKETS = 'modules/data/attendees/SET_TICKETS';

// NOTE: tickets data will be placed here for now since no other part of the app uses it
const initialState = fromJS({
  total: 0,
  attendees: [],
  tickets: {},
});

const reducer = handleActions({
  [SET_CONFERENCE_ATTENDEES]: (state, action = {}) => state.merge(fromJS({
    attendees: action.payload.results || [],
    total: action.payload.count,
  })),
  [SET_TICKETS]: (state, action = {}) => state.set('tickets', fromJS(action.payload)),
  [UPDATE_CONFERENCE_ATTENDEE]: (state, action = {}) => {
    const index = state.get('attendees').findIndex((item) => item.get('id') === action.payload.id)
    return state.updateIn(['attendees'], (attendees) => attendees.update(index, (item) => item.merge(action.payload)));
  },
}, initialState);

export const setConferenceAttendeesAction = createAction(SET_CONFERENCE_ATTENDEES, (attendees) => (attendees));
export const updateConferenceAttendeeAction = createAction(UPDATE_CONFERENCE_ATTENDEE, (attendee) => (attendee));
export const setTicketsAction = createAction(SET_TICKETS, (tickets) => (tickets));

export const getConferenceAttendeesAsync = (conference, offset = 0) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/chkn/attendees/', {
      params: {
        conference,
        limit: ATTENDEE_LIMIT,
        offset,
        search: getState().getIn(['conferenceAttendeesUi', 'searchKey']),
      },
    },);
    resp = _.get(resp, 'data');
    dispatch(setConferenceAttendeesAction(resp));

    return resp;
  };
};

export const getTicketsAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/tickets/', { params: { conference } });
    resp = _.get(resp, 'data');

    dispatch(setTicketsAction(arrayToObject(resp)));

    return resp;
  };
};

export const updateConferenceAttendeeAsync = (data, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(`/chkn/attendees/${data.id}/`, data, { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(updateConferenceAttendeeAction(resp));

    return resp;
  };
};

export default reducer;