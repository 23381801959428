import axios from 'axios';
import _ from 'lodash';

const getToken = () => `Bearer ${localStorage.getItem('token') || ''}`;

const validateAuth = (error) => {
  const status = _.get(error, 'response.status');
  if (status === 401) {
    localStorage.removeItem('token');
    window.location.reload();
  }
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiGateway = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
  timeout: 60000,
});

export const apiAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
  headers: {
    Authorization: getToken(),
  },
});

apiAuth.interceptors.response.use(null, (error) => {
  validateAuth(error);
  return Promise.reject(error);
});

export default api;
