import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_DIAMOND_INQUIRIES = 'modules/data/diamond-inquiries/SET_DIAMOND_INQUIRIES';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_DIAMOND_INQUIRIES]: (state, action = {}) => fromJS(action.payload || []),
}, initialState);

export const setDiamondInquiriesAction = createAction(SET_DIAMOND_INQUIRIES, (data) => (data));

export const getDiamondInquiriesAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/diamond_inquiries/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setDiamondInquiriesAction(resp));

    return resp;
  };
};

export default reducer;
