import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

import { getConferencesAsync } from './conferences';
import { getExhibitorsAsync } from './exhibitors';
import { getPrimaryContactsAsync } from './primary-contacts';
import { getOrdersAsync } from './orders';
import {
  getExhibitorTypesAsync,
  getBoothTypesAsync,
} from './lookups';

const SET_CRM = 'modules/data/crm/SET_CRM';

const initialState = fromJS({
  conferences: [],
  exhibitors: [],
  primaryContacts: [],
  orders: [],
  boothTypes: [],
  exhibitorTypes: [],
});

const reducer = handleActions({
  [SET_CRM]: (state, action = {}) => fromJS(action.payload || []),
}, initialState);

export const setCrmAction = createAction(SET_CRM, (data) => (data));

export const getCrmAsync = () => {
  return async (dispatch) => {
    const conferences = await dispatch(getConferencesAsync());
    let exhibitors = _.map(conferences, ({ id }) => dispatch(getExhibitorsAsync(id)));
    let primaryContacts = _.map(conferences, ({ id }) => dispatch(getPrimaryContactsAsync(id)));
    let orders = _.map(conferences, ({ id }) => dispatch(getOrdersAsync(id)));
    let boothTypes = _.map(conferences, ({ id }) => dispatch(getBoothTypesAsync(id)));
    let exhibitorTypes = _.map(conferences, ({ id }) => dispatch(getExhibitorTypesAsync(id)));

    exhibitors = await Promise.all(exhibitors);
    primaryContacts = await Promise.all(primaryContacts);
    orders = await Promise.all(orders);
    boothTypes = await Promise.all(boothTypes);
    exhibitorTypes = await Promise.all(exhibitorTypes);

    const resp = {
      conferences,
      exhibitors: _.flatten(exhibitors),
      primaryContacts: _.flatten(primaryContacts),
      orders: _.flatten(orders),
      boothTypes: _.flatten(boothTypes),
      exhibitorTypes: _.flatten(exhibitorTypes),
    };
    dispatch(setCrmAction(resp));

    return resp;
  };
};

export default reducer;
