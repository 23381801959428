import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_LEDGERS = 'modules/data/ledgers/SET_LEDGERS';
const ADD_LEDGER = 'modules/data/ledgers/ADD_LEDGER';
const UPDATE_LEDGER = 'modules/data/ledgers/UPDATE_LEDGER';
const REMOVE_LEDGER = 'modules/data/ledgers/REMOVE_LEDGER';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_LEDGERS]: (state, action = {}) => fromJS(action.payload || []),
  [ADD_LEDGER]: (state, action = {}) => state.push(fromJS(action.payload || {})),
  [UPDATE_LEDGER]: (state, action = {}) => {
    const { key, data = {} } = action.payload || {};
    return state.update(state.findIndex((item) => item.get(key) === _.get(data, key)),
      (item) => item.merge(data));
  },
  [REMOVE_LEDGER]: (state, action = {}) => {
    const { key, value } = action.payload || {};
    return state.delete(state.findIndex((item) => item.get(key) === value));
  },
}, initialState);

export const setLedgersAction = createAction(SET_LEDGERS, (ledgers) => (ledgers));
export const addLedgerAction = createAction(ADD_LEDGER, (data) => (data));
export const updateLedgerAction = createAction(UPDATE_LEDGER, (key, data) => ({ key, data }));
export const removeLedgerAction = createAction(REMOVE_LEDGER, (key, value) => ({ key, value }));

export const getLedgersAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/internal/ledgers/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setLedgersAction(resp));

    return resp;
  };
};

export const addLedgerAsync = (data, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.post('/internal/ledgers/', data, { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(addLedgerAction(resp));

    return resp;
  };
};

export const updateLedgerAsync = (id, data, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(`/internal/ledgers/${id}/`, data, { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(updateLedgerAction('id', resp));

    return resp;
  };
};

export const removeLedgerAsync = (id, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    const resp = await apiAuth.delete(`/internal/ledgers/${id}/`, { params: { conference } });
    dispatch(removeLedgerAction('id', _.toNumber(id)));

    return resp;
  };
};

export default reducer;
