import _ from 'lodash';

export const OrderTypes = {
  ADDITIONAL_ORDER: 'additional-order',
  UPGRADE: 'upgrade',
  CHILD_ORDER: 'child-order',
};

export const OrderStatus = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
};

export const BoothTypes = {
  SUPER: 'super-booth',
  DIAMOND: 'diamond-booth',
  SKYSCRAPER: 'skyscraper-booth',
  PREMIUM: 'premium-booth',
  CLASSIC: 'classic-booth',
  BASIC: 'basic-booth',
  SPONSOR: 'sponsor',
};

export const BoothTypeTVCount = {
  [BoothTypes.SUPER]: 1,
  [BoothTypes.DIAMOND]: 1,
  [BoothTypes.SKYSCRAPER]: 1,
  [BoothTypes.PREMIUM]: 1,
  [BoothTypes.CLASSIC]: 0,
  [BoothTypes.BASIC]: 0,
};

export const BoothTypeChairCount = {
  [BoothTypes.SUPER]: 4,
  [BoothTypes.DIAMOND]: 2,
  [BoothTypes.SKYSCRAPER]: 2,
  [BoothTypes.PREMIUM]: 2,
  [BoothTypes.CLASSIC]: 2,
  [BoothTypes.BASIC]: 1,
};

export const ExhibitorTypes = {
  TRAFFIC_SOURCE: 'Traffic Source',
  SERVICE_PROVIDER: 'Service Provider',
  NETWORK: 'Network',
  AGENCY: 'Agency',
  ADVERTISER: 'Advertiser',
};

export const BoothStatus = {
  AVAILABLE: 'available',
  PURCHASED: 'purchased',
  RESERVED: 'reserved',
  IN_CART: 'in_cart',
};

export const BoothZones = {
  A: 'A',
  B: 'B',
  C: 'C',
};

export const PaymentStatus = {
  PAID: 'paid',
  PENDING: 'pending',
  UNPAID: 'unpaid',
  BOUNCEBACK: 'bounceback',
  OVERPAID: 'overpaid',
  PARTIAL: 'partial',
  REVIEW: 'review',
};

export const ModeOfPayment = {
  INVOICE: 'invoice',
  CREDIT_CARD: 'credit_card',
};

export const SponsorshipTypeStatus = {
  AVAILABLE: 'available',
  PURCHASED: 'purchased',
  RESERVED: 'reserved',
};

export const AdditionalOrderTypes = {
  TV_SCREEN: 'tv-screen',
  ONE_EXTRA_CHAIR: 'one-extra-chair',
  TWO_EXTRA_CHAIR: 'two-extra-chair',
  EXHIBITOR_TICKET: 'exhibitor-ticket',
  SPONSOR_TICKET: 'sponsor-ticket',
  PULLUP_EXTRA: 'pull-up-banner',
};

export const ArtworkTypes = {
  // SUPER_PULL_UP_BANNER: 'super-pull-up-banner',
  SKYSCRAPER_BACKWALL: 'skyscraper-back-wall',
  BACKWALL: 'back-wall',
  BACKWALL_2: 'back-wall-2',
  PULL_UP_BANNER: 'pull-up-banner',
  PULL_UP_BANNER_2: 'pull-up-banner-2',
  BASIC_LOGO: 'basic-logo',
  LOGO: 'logo',
};

export const BoothArtworks = {
  [BoothTypes.SUPER]: [ArtworkTypes.BACKWALL,
    ArtworkTypes.PULL_UP_BANNER, ArtworkTypes.PULL_UP_BANNER_2, ArtworkTypes.SUPER_PULL_UP_BANNER, ArtworkTypes.LOGO],
  [BoothTypes.SKYSCRAPER]: [ArtworkTypes.BACKWALL, ArtworkTypes.SKYSCRAPERPULL_BACKWALL,
    ArtworkTypes.PULL_UP_BANNER, ArtworkTypes.LOGO],
  [BoothTypes.PREMIUM]: [ArtworkTypes.BACKWALL, ArtworkTypes.LOGO, ArtworkTypes.PULL_UP_BANNER],
  [BoothTypes.DIAMOND]: [ArtworkTypes.BACKWALL, ArtworkTypes.PULL_UP_BANNER, ArtworkTypes.LOGO],
  [BoothTypes.CLASSIC]: [ArtworkTypes.BACKWALL, ArtworkTypes.LOGO],
  [BoothTypes.BASIC]: [ArtworkTypes.BASIC_LOGO, ArtworkTypes.LOGO],
};

export const NewBoothArtworks = {
  [BoothTypes.SUPER]: [ArtworkTypes.BACKWALL,
    ArtworkTypes.PULL_UP_BANNER, ArtworkTypes.PULL_UP_BANNER_2, ArtworkTypes.SUPER_PULL_UP_BANNER, ArtworkTypes.LOGO],
  [BoothTypes.SKYSCRAPER]: [ArtworkTypes.BACKWALL, ArtworkTypes.SKYSCRAPERPULL_BACKWALL,
    ArtworkTypes.PULL_UP_BANNER, ArtworkTypes.LOGO],
  [BoothTypes.PREMIUM]: [ArtworkTypes.BACKWALL, ArtworkTypes.LOGO],
  [BoothTypes.DIAMOND]: [ArtworkTypes.BACKWALL, ArtworkTypes.PULL_UP_BANNER, ArtworkTypes.LOGO],
  [BoothTypes.CLASSIC]: [ArtworkTypes.BACKWALL, ArtworkTypes.LOGO],
  [BoothTypes.BASIC]: [ArtworkTypes.BASIC_LOGO, ArtworkTypes.LOGO],
};

export const ArtworkStatus = {
  APPROVED: 'approved',
  PARTIAL: 'partial',
  IN_REVIEW: 'in-review',
  REJECTED: 'rejected',
  NOT_SUBMITTED: 'not-submitted',
  NOT_APPLICABLE: 'not-applicable',
};

export const CompanyDetailStatus = {
  APPROVED: 'approved',
  COMPLETED: 'completed',
  DRAFT: 'draft',
  INCOMPLETE: 'incomplete',
  UPDATED: 'updated',
  PUBLISHED: 'published'
};

export const CompanyStatus = {
  APPROVED: 'Approved',
  COMPLETED: 'Completed',
  DRAFT: 'Draft',
  INCOMPLETE: 'Incomplete',
  UPDATED: 'Updated',
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublish'
}

export const COMPANY_REQUIRED_FIELDS = [
  'name',
  'exhibitorType',
  'country',
  'description',
  'website',
  // 'areasOfSpecialtyNames',
];

export const LedgerTypes = {
  CUSTOM_INVOICE: 'custom-invoice',
  CUSTOM_REFUND: 'custom-refund',
  PAYMENT: 'payment',
  CANCELLATION: 'cancellation',
  REFUND: 'refund',
  WRITE_OFF: 'write-off',
  CREDIT_CARD: 'credit-card',
  INVOICE: 'invoice',
};

export const ViewPresets = {
  OVERVIEW: 'overview',
  ARTWORKS: 'artworks',
  COMPANY_INFO: 'company-info',
  FURNITURES: 'furnitures',
  CONTACTS: 'contacts',
  SPONSORSHIPS: 'sponsorships',
};

export const EmailStatus = {
  OPEN: 'open',
  DELIVERY: 'delivery',
  BOUNCE: 'bounce',
};

export const DocumentTypes = {
  LEDGER: 'ledger',
};

export const OrdersTableWidth = {
  [ViewPresets.OVERVIEW]: '2300px',
  [ViewPresets.ARTWORKS]: '3000px',
  [ViewPresets.COMPANY_INFO]: '2400px',
  [ViewPresets.FURNITURES]: '1350px',
  [ViewPresets.CONTACTS]: '1660px',
  [ViewPresets.SPONSORSHIPS]: '2000px',
};

export const FiltersCategory = {
  BOOTH_TYPE: 'boothType',
  EXHIBITOR_TYPE: 'companyType',
  PAYMENT_STATUS: 'paymentStatus',
  COMPANY_DETAIL_STATUS: 'companyDetailStatus',
  ARTWORK_STATUS: 'artworkStatus',
  BOOTH_ZONE: 'boothZone',
  ORDER_STATUS: 'orderStatus',
};

export const FiltersLength = {
  [FiltersCategory.BOOTH_TYPE]: _.keys(BoothTypes).length,
  [FiltersCategory.EXHIBITOR_TYPE]: _.keys(ExhibitorTypes).length,
  [FiltersCategory.PAYMENT_STATUS]: _.keys(PaymentStatus).length,
  [FiltersCategory.COMPANY_DETAIL_STATUS]: _.keys(CompanyDetailStatus).length,
  [FiltersCategory.ARTWORK_STATUS]: _.keys(ArtworkStatus).length,
  [FiltersCategory.BOOTH_ZONE]: _.keys(BoothZones).length,
  [FiltersCategory.ORDER_STATUS]: _.keys(OrderStatus).length,
};

export const DefaultFilters = {
  [FiltersCategory.BOOTH_TYPE]: [
    BoothTypes.BASIC,
    BoothTypes.CLASSIC,
    BoothTypes.DIAMOND,
    BoothTypes.PREMIUM,
    BoothTypes.SKYSCRAPER,
    BoothTypes.SUPER,
    BoothTypes.SPONSOR,
  ],
  [FiltersCategory.EXHIBITOR_TYPE]: [
    ExhibitorTypes.TRAFFIC_SOURCE,
    ExhibitorTypes.SERVICE_PROVIDER,
    ExhibitorTypes.NETWORK,
    ExhibitorTypes.AGENCY,
    ExhibitorTypes.ADVERTISER,
  ],
  [FiltersCategory.PAYMENT_STATUS]: [
    PaymentStatus.PAID,
    PaymentStatus.PENDING,
    PaymentStatus.UNPAID,
    PaymentStatus.BOUNCEBACK,
    PaymentStatus.OVERPAID,
    PaymentStatus.PARTIAL,
    PaymentStatus.REVIEW,
  ],
  [FiltersCategory.COMPANY_DETAIL_STATUS]: [
    CompanyDetailStatus.APPROVED,
    CompanyDetailStatus.UPDATED,
    CompanyDetailStatus.DRAFT,
    CompanyDetailStatus.COMPLETED,
    CompanyDetailStatus.INCOMPLETE,
    CompanyDetailStatus.PUBLISHED
  ],
  [FiltersCategory.ARTWORK_STATUS]: [
    ArtworkStatus.APPROVED,
    ArtworkStatus.IN_REVIEW,
    ArtworkStatus.NOT_APPLICABLE,
    ArtworkStatus.NOT_SUBMITTED,
    ArtworkStatus.PARTIAL,
    ArtworkStatus.REJECTED,
  ],
  [FiltersCategory.BOOTH_ZONE]: [
    BoothZones.A,
    BoothZones.B,
    BoothZones.C,
  ],
  [FiltersCategory.ORDER_STATUS]: [
    OrderStatus.ACTIVE,
  ],
};

export const CrmStatus = {
  SOLD: 'sold',
  CONTACTED: 'contacted',
  IN_PROGRESS: 'in-progress',
  LOST: 'lost',
};

export const EditorTags = {
  CONTACT_FIRST_NAME: 'contactFirstName',
  BOOTH_TYPE: 'boothType',
  ORDER_REFERENCE: 'orderReference',
};

export const excludedExhibitorTypes = {
  awa23: ['Network', 'Advertiser'],
  awd24: ['Network', 'Advertiser'],
  awe24: ['Network', 'Advertiser'],
  awa24: ['Network', 'Advertiser']
}
