import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_PROOF_OF_PAYMENTS = 'modules/data/proof-of-payments/SET_PROOF_OF_PAYMENTS';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_PROOF_OF_PAYMENTS]: (state, action = {}) => fromJS(action.payload || []),
}, initialState);

export const setProofOfPaymentsAction = createAction(SET_PROOF_OF_PAYMENTS,
  (payments) => (payments));

export const getProofOfPaymentsAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/internal/proof_of_payments/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setProofOfPaymentsAction(resp));

    return resp;
  };
};

export default reducer;
