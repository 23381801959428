import _ from 'lodash';
import moment from 'moment';
import { notification } from 'antd';

import { PaymentStatus } from './constants';
import convertToCsv from './convert-to-csv';

const { REACT_APP_WEBSITE_URL } = process.env;

// Use custom template delimiters.
_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

export const getQuery = (query, name) => {
  const params = new URLSearchParams(query);
  return params.get(name);
};

export const isEmpty = (value) => {
  return _.isNil(value) || _.trim(value) === '';
};

export const compact = (...values) => {
  return _.filter(_.flattenDeep(values), (value) => !isEmpty(value));
};

export const dateNow = () => {
  return moment();
};

export const formatDate = (date, format = 'MM/DD/YY') => {
  const parsedDate = new Date(date);

  if (moment(parsedDate).isValid()) return moment(parsedDate).format(format);
  return '';
};

export const formatPrice = (value) => {
  const price = _.toNumber(value) || 0;
  return price.toLocaleString('en');
};

export const extractTextFromHTML = value => {
  let span = document.createElement('span');
  span.innerHTML = value;
  return span.textContent || span.innerText;
};
    

export const parseDate = (date) => {
  if (moment(date).isValid()) return moment(date);
  return moment();
};

export const hasSubstring = (left, right) => {
  const leftLowerCase = _.toLower(left);
  const rightLowerCase = _.toLower(right);

  return leftLowerCase.indexOf(rightLowerCase) >= 0;
};

export const showNotification = (message = '', description = false) => {
  notification.open({
    message,
    description,
  });
};

export const stripHtml = (html) => {
  // Remove all tags
  return _.replace(html, /<\/?[^>]+(>|$)/g, '');
};

export const truncate = (value, length) => {
  return _.truncate(value, {
    length,
    separator: ' ',
  });
};

export const join = (list, separator = ', ') => {
  return _.join(list, separator);
};

export const replaceNewLine = (html) => {
  // Replace newline with BR
  return _.replace(html, /(?:\n)/g, '<br />');
};

export const exportToCsv = (data, columns, fileName) => {
  const csv = convertToCsv(data, columns);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const defaultName = fileName || `order-grid-${dateNow().format('YYYY-MM-DD')}.csv`;

  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', defaultName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const getPaymentStatus = (total, amountDue) => {
  if (amountDue === 0) return PaymentStatus.PAID;
  if (total === amountDue) return PaymentStatus.UNPAID;
  if (amountDue < 0) return PaymentStatus.OVERPAID;
  if (amountDue > 0) return PaymentStatus.PARTIAL;
  return PaymentStatus.PENDING;
};

export const getCartUrl = (conference) => {
  if (conference.indexOf('awe') >= 0) {
    return `${REACT_APP_WEBSITE_URL}europe/order-summary`;
  } else if (conference.indexOf('awd') >= 0) {
    if (process.env.NODE_ENV === 'development') {
      return `${REACT_APP_WEBSITE_URL}awg-dubai/order-summary`;
    } else {
      return `${REACT_APP_WEBSITE_URL}dubai/order-summary`;
    }
  }
  return `${REACT_APP_WEBSITE_URL}asia/order-summary`;
};

export const compileTemplate = (template, data) => {
  const compiled = _.template(template);
  return compiled(data);
};

export const parseFormData = (data) => {
  const form = new FormData();
  _.forOwn(data, (value, key) => {
    if (_.isArray(value)) _.forEach(value, (item) => form.append(key, item));
    else form.append(key, value);
  });
  return form;
};

export const processBoothType = (input) => {
  let regexPattern = /-\d+sqm/;
  let resultString = input.replace(regexPattern, "");
  return resultString.split('-').join(' ') || resultString;
}
