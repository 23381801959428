import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_WAIT_LIST = 'modules/data/wait-list/SET_WAIT_LIST';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_WAIT_LIST]: (state, action = {}) => fromJS(action.payload || []),
}, initialState);

export const setWaitListAction = createAction(SET_WAIT_LIST, (list) => (list));

export const getWaitListAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/internal/waitlist/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setWaitListAction(resp));

    return resp;
  };
};

export default reducer;
