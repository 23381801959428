import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_CRM_NOTES = 'modules/data/crm-notes/SET_CRM_NOTES';
const ADD_CRM_NOTE = 'modules/data/crm-notes/ADD_CRM_NOTE';
const UPDATE_CRM_NOTE = 'modules/data/crm-notes/UPDATE_CRM_NOTE';
const REMOVE_CRM_NOTE = 'modules/data/crm-notes/REMOVE_CRM_NOTE';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_CRM_NOTES]: (state, action = {}) => fromJS(action.payload || []),
  [ADD_CRM_NOTE]: (state, action = {}) => state.push(fromJS(action.payload || {})),
  [UPDATE_CRM_NOTE]: (state, action = {}) => {
    const { key, data = {} } = action.payload || {};
    return state.update(state.findIndex((item) => item.get(key) === _.get(data, key)),
      (item) => item.merge(data));
  },
  [REMOVE_CRM_NOTE]: (state, action = {}) => {
    const { key, value } = action.payload || {};
    return state.delete(state.findIndex((item) => item.get(key) === value));
  },
}, initialState);

export const setCrmNotesAction = createAction(SET_CRM_NOTES, (data) => (data));
export const addCrmNoteAction = createAction(ADD_CRM_NOTE, (data) => (data));
export const updateCrmNoteAction = createAction(UPDATE_CRM_NOTE,
  (key, data) => ({ key, data }));
export const removeCrmNoteAction = createAction(REMOVE_CRM_NOTE,
  (key, value) => ({ key, value }));

export const getCrmNotesAsync = (conference) => {
  return async (dispatch, getState, { apiGateway }) => {
    let resp = await apiGateway.get('/api/misc/crm/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setCrmNotesAction(resp));

    return resp;
  };
};

export const addCrmNoteAsync = (data) => {
  return async (dispatch, getState, { apiGateway }) => {
    let resp = await apiGateway.post('/api/misc/crm/', data);
    resp = _.get(resp, 'data');
    dispatch(addCrmNoteAction(resp));

    return resp;
  };
};

export const updateCrmNoteAsync = (id, data) => {
  return async (dispatch, getState, { apiGateway }) => {
    let resp = await apiGateway.put(`/api/misc/crm/${id}/`, data);
    resp = _.get(resp, 'data');
    dispatch(updateCrmNoteAction('id', resp));

    return resp;
  };
};

export const removeCrmNoteAsync = (id) => {
  return async (dispatch, getState, { apiGateway }) => {
    const resp = await apiGateway.delete(`/api/misc/crm/${id}/`);
    dispatch(removeCrmNoteAction('id', id));

    return resp;
  };
};

export default reducer;
