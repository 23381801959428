import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_MAIL_REPORTS = 'modules/data/mail-reports/SET_MAIL_REPORTS';
const REMOVE_MAIL_REPORT = 'modules/data/mail-reports/REMOVE_MAIL_REPORT';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_MAIL_REPORTS]: (state, action = {}) => fromJS(action.payload || []),
  [REMOVE_MAIL_REPORT]: (state, action = {}) => {
    const { key, value } = action.payload || {};
    return state.delete(state.findIndex((item) => item.get(key) === value));
  },
}, initialState);

export const setMailReportsAction = createAction(SET_MAIL_REPORTS, (data) => (data));
export const removeMailReportAction = createAction(REMOVE_MAIL_REPORT,
  (key, value) => ({ key, value }));

export const getMailReportsAsync = (conference) => {
  return async (dispatch, getState, { apiGateway }) => {
    let resp = await apiGateway.get('/api/mail/reports/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setMailReportsAction(resp));

    return resp;
  };
};

export const removeMailReportAsync = (id) => {
  return async (dispatch, getState, { apiGateway }) => {
    const resp = await apiGateway.delete(`/api/mail/reports/${id}/`);
    dispatch(removeMailReportAction('id', id));

    return resp;
  };
};

export default reducer;
