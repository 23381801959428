import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_MAIL_AUTOMATIONS = 'modules/data/mail-automations/SET_MAIL_AUTOMATIONS';
const ADD_MAIL_AUTOMATION = 'modules/data/mail-automations/ADD_MAIL_AUTOMATION';
const UPDATE_MAIL_AUTOMATION = 'modules/data/mail-automations/UPDATE_MAIL_AUTOMATION';
const REMOVE_MAIL_AUTOMATION = 'modules/data/mail-automations/REMOVE_MAIL_AUTOMATION';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_MAIL_AUTOMATIONS]: (state, action = {}) => fromJS(action.payload || []),
  [ADD_MAIL_AUTOMATION]: (state, action = {}) => state.push(fromJS(action.payload || {})),
  [UPDATE_MAIL_AUTOMATION]: (state, action = {}) => {
    const { key, data = {} } = action.payload || {};
    return state.update(state.findIndex((item) => item.get(key) === _.get(data, key)),
      (item) => item.merge(data));
  },
  [REMOVE_MAIL_AUTOMATION]: (state, action = {}) => {
    const { key, value } = action.payload || {};
    return state.delete(state.findIndex((item) => item.get(key) === value));
  },
}, initialState);

export const setMailAutomationsAction = createAction(SET_MAIL_AUTOMATIONS, (data) => (data));
export const addMailAutomationAction = createAction(ADD_MAIL_AUTOMATION, (data) => (data));
export const updateMailAutomationAction = createAction(UPDATE_MAIL_AUTOMATION,
  (key, data) => ({ key, data }));
export const removeMailAutomationAction = createAction(REMOVE_MAIL_AUTOMATION,
  (key, value) => ({ key, value }));

export const getMailAutomationsAsync = (conference) => {
  return async (dispatch, getState, { apiGateway }) => {
    let resp = await apiGateway.get('/api/mail/automations/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setMailAutomationsAction(resp));

    return resp;
  };
};

export const addMailAutomationAsync = (data) => {
  return async (dispatch, getState, { apiGateway }) => {
    let resp = await apiGateway.post('/api/mail/automations/', data);
    resp = _.get(resp, 'data');
    dispatch(addMailAutomationAction(resp));

    return resp;
  };
};

export const updateMailAutomationAsync = (id, data) => {
  return async (dispatch, getState, { apiGateway }) => {
    let resp = await apiGateway.put(`/api/mail/automations/${id}/`, data);
    resp = _.get(resp, 'data');
    dispatch(updateMailAutomationAction('id', resp));

    return resp;
  };
};

export const removeMailAutomationAsync = (id) => {
  return async (dispatch, getState, { apiGateway }) => {
    const resp = await apiGateway.delete(`/api/mail/automations/${id}/`);
    dispatch(removeMailAutomationAction('id', id));

    return resp;
  };
};

export default reducer;
