import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_ARTWORKS = 'modules/data/artworks/SET_ARTWORKS';
const ADD_ARTWORK = 'modules/data/artworks/ADD_ARTWORK';
const UPDATE_ARTWORK = 'modules/data/artworks/UPDATE_ARTWORK';
const REMOVE_ARTWORK = 'modules/data/artworks/REMOVE_ARTWORK';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_ARTWORKS]: (state, action = {}) => fromJS(action.payload || []),
  [ADD_ARTWORK]: (state, action = {}) => state.push(fromJS(action.payload || {})),
  [UPDATE_ARTWORK]: (state, action = {}) => {
    const { key, data = {} } = action.payload || {};
    return state.update(state.findIndex((item) => item.get(key) === _.get(data, key)),
      (item) => item.merge(data));
  },
  [REMOVE_ARTWORK]: (state, action = {}) => {
    const { key, value } = action.payload || {};
    return state.delete(state.findIndex((item) => item.get(key) === value));
  },
}, initialState);

export const setArtworksAction = createAction(SET_ARTWORKS, (artworks) => (artworks));
export const addArtworkAction = createAction(ADD_ARTWORK, (data) => (data));
export const updateArtworkAction = createAction(UPDATE_ARTWORK, (key, data) => ({ key, data }));
export const removeArtworkAction = createAction(REMOVE_ARTWORK, (key, value) => ({ key, value }));

export const getArtworksAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/internal/artworks/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setArtworksAction(resp));

    return resp;
  };
};

export const addArtworkAsync = (data) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.post('/internal/artworks/', data);
    resp = _.get(resp, 'data');
    dispatch(addArtworkAction(resp));

    return resp;
  };
};

export const updateArtworkAsync = (id, data, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(`/internal/artworks/${id}/`, data, { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(updateArtworkAction('id', resp));

    return resp;
  };
};

export const removeArtworkAsync = (id, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    const resp = await apiAuth.delete(`/internal/artworks/${id}/`, { params: { conference } });
    dispatch(removeArtworkAction('id', id));

    return resp;
  };
};

export default reducer;
