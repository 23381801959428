import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const SET_SEARCH_KEY = 'modules/ui/booth-transfer-requests/SET_SEARCH_KEY';
const SET_SELECTED_ROW = 'modules/ui/booth-transfer-requests/SET_SELECTED_ROW';

const initialState = fromJS({
  searchKey: '',
  selectedRow: '',
});

const reducer = handleActions(
  {
    [SET_SEARCH_KEY]: (state, action = {}) =>
      state.set('searchKey', action.payload),
    [SET_SELECTED_ROW]: (state, action = {}) =>
      state.set('selectedRow', fromJS(action.payload)),
  },
  initialState,
);

export const setSearchKeyAction = createAction(SET_SEARCH_KEY, (key) => key);
export const setSelectedRowAction = createAction(
  SET_SELECTED_ROW,
  (value) => value,
);

export default reducer;
