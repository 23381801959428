import { createGlobalStyle } from 'styled-components';

import Colors from './colors';

const {
  GRAY,
  GREEN,
  YELLOW,
  RED,
  BLUE,
  ORANGE,
  TURQUOISE,
  DARKGREEN
} = Colors;

export default createGlobalStyle`
  html {
     height: 100vh;
     width: 100%;
     overflow: hidden;

    body {
      color: #3C4B61;
      font-family: "Lato", Helvetica, "Helvetica Neue", Arial, sans-serif;
      font-size: 12px;
      font-weight: 500;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      
      h1, h2, h3, h4, h5, h6, p {
        margin: 0;
        color: inherit;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      // Ant Design Override
      .ant-select-dropdown {
        font-size: 13px;
      }
      .ant-btn[disabled] {
        color: #797979;
      }
      .ant-btn.ant-btn-primary[disabled] {
        border: 1px solid #D9D9D9;
      }
      .ant-btn.ant-btn-danger {
        background-color: #DC402B;
      }
      .ant-btn.ant-btn-danger[disabled],
      .ant-btn.ant-btn-danger[disabled]:hover {
        background-color: #D9D9D9;
      }
      .ant-btn.ant-btn-danger:hover,
      .ant-btn.ant-btn-danger:focus {
        color: #FFFFFF;
        background-color: #F5222D;
        border-color: #F5222D;
      }
      .ant-table-tbody > tr > td {
        word-break: break-word;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        padding: 12px 10px;
      }
    }
  }

  .app {
    background-color: #FFFFFF;
    min-height: 100%;
    min-width: 100%;
  }

  div.ql-editor > p {
    margin-bottom: 1em;
  }

  .circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${GRAY};
    border-radius: 50%;
    margin: 0 4px;

    &.paid {
      background-color: ${GREEN};
    }
    &.pending {
      background-color: ${YELLOW};
    }
    &.review {
      background-color: ${BLUE};
    }
    &.unpaid {
      background-color: ${RED};
    }
    &.overpaid {
      background-color: ${ORANGE};
    }
    &.partial {
      background-color: ${TURQUOISE};
    }
    &.available {
      background-color: ${GREEN};
    }
    &.purchased {
      background-color: ${YELLOW};
    }
    &.reserved {
      background-color: ${ORANGE};
    }
    &.in_cart {
      background-color: ${GRAY};
    }
    &.open {
      background-color: ${GREEN};
    }
    &.delivery {
      background-color: ${BLUE};
    }
    &.bounce {
      background-color: ${RED};
    }
    &.process {
      background-color: ${GRAY};
    }
    &.sold {
      background-color: ${GREEN};
    }
    &.in-progress {
      background-color: ${BLUE};
    }
    &.contacted {
      background-color: ${YELLOW};
    }
    &.lost {
      background-color: ${GRAY};
    }
  }

  .highlight {
    color: ${GRAY};
    &.paid {
      color: ${GREEN};
    }
    &.pending {
      color: ${YELLOW};
    }
    &.review {
      color: ${BLUE};
    }
    &.unpaid, &.not-requested {
      color: ${RED};
    }
    &.overpaid {
      color: ${ORANGE};
    }
    &.partial {
      color: ${TURQUOISE};
    }
    &.approved,
    &.completed, &.requested {
      color: ${GREEN};
    }
    &.published {
      color: ${DARKGREEN};
    }
    &.updated {
      color: ${BLUE};
    }
    &.incomplete {
      color: ${YELLOW};
    }
    &.in-review {
      color: ${BLUE};
    }
    &.rejected {
      color: ${ORANGE};
    }
    &.not-submitted {
      color: ${RED};
    }
    &.available {
      color: ${GREEN};
    }
    &.purchased {
      color: ${YELLOW};
    }
    &.reserved {
      color: ${ORANGE};
    }
    &.in_cart {
      color: ${GRAY};
    }
  }

  .ant-drawer {
    transform: none !important;
  }

  .required:before {
    display: inline-block;
    margin-right: 4px;
    content: "*";
    font-size: 14px;
    color: #F5222D;
  }

  :focus {
    outline: none;
  }
`;
