import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import {
  ViewPresets,
  DefaultFilters,
} from 'common/constants';

const SET_VIEW = 'modules/ui/orders/SET_VIEW';
const SET_SEARCH_KEY = 'modules/ui/orders/SET_SEARCH_KEY';
const SET_SELECTED_ROW_KEYS = 'modules/ui/orders/SET_SELECTED_ROW_KEYS';
const SET_FILTERS = 'modules/ui/orders/SET_FILTERS';

const initialState = fromJS({
  view: ViewPresets.OVERVIEW,
  searchKey: '',
  selectedRowKeys: [],
  filters: DefaultFilters,
});

const reducer = handleActions({
  [SET_VIEW]: (state, action = {}) => state.set('view', action.payload),
  [SET_SEARCH_KEY]: (state, action = {}) => state.set('searchKey', action.payload),
  [SET_SELECTED_ROW_KEYS]: (state, action = {}) => state.set('selectedRowKeys',
    fromJS(action.payload)),
  [SET_FILTERS]: (state, action = {}) => state.set('filters', fromJS(action.payload)),
}, initialState);

export const setViewAction = createAction(SET_VIEW, (view) => (view));
export const setSearchKeyAction = createAction(SET_SEARCH_KEY, (key) => (key));
export const setSelectedRowKeysAction = createAction(SET_SELECTED_ROW_KEYS, (keys) => (keys));
export const setFiltersAction = createAction(SET_FILTERS, (filters) => (filters));

export default reducer;
