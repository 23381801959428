const Countries = [{
  id: '2GD2vzXRDMFb6QZPK',
  name: 'Bosnia and Herzegovina',
  dialCode: '+387',
  code: 'BA',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: '2GK2ew5TZefZKdstt',
  name: 'Mexico',
  dialCode: '+52',
  code: 'MX',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: '2jbfWSPFKzkskKudG',
  name: 'Pakistan',
  dialCode: '+92',
  code: 'PK',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: '33xQdgstyfccH23R2',
  name: 'Indonesia',
  dialCode: '+62',
  code: 'ID',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: '3qHKetWf959zobkJe',
  name: 'Korea, Democratic People\'s Republic of',
  dialCode: '+850',
  code: 'KP',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: '3SnRSKBdcpZQmEDpK',
  name: 'Seychelles',
  dialCode: '+248',
  code: 'SC',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: '4YvG99nfQH8C5WxZN',
  name: 'land Islands',
  dialCode: '',
  code: 'AX',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: '52CbZf73Davjm3y6K',
  name: 'Poland',
  dialCode: '+48',
  code: 'PL',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: '5fMtDQx9TZbnRqPTh',
  name: 'Japan',
  dialCode: '+81',
  code: 'JP',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: '5gcNiwix3zTYXzYrZ',
  name: 'Cyprus',
  dialCode: '+537',
  code: 'CY',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: '5MYTKqdYw8Yn9TwWg',
  name: 'Samoa',
  dialCode: '+685',
  code: 'WS',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: '66GBTgcmuetqumbzy',
  name: 'Ukraine',
  dialCode: '+380',
  code: 'UA',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: '68KZF5cSkDJyrbtE5',
  name: 'Egypt',
  dialCode: '+20',
  code: 'EG',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: '6DgvH283GGqBpF9io',
  name: 'Maldives',
  dialCode: '+960',
  code: 'MV',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: '6GH6gyigYrrefHgha',
  name: 'Madagascar',
  dialCode: '+261',
  code: 'MG',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: '79wkqpxeDTaxoc2c7',
  name: 'Martinique',
  dialCode: '+596',
  code: 'MQ',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: '7eGqmuoFJ5RF9d8Bb',
  name: 'Faroe Islands',
  dialCode: '+298',
  code: 'FO',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: '7nWBecjiTWmR8e26Z',
  name: 'Vanuatu',
  dialCode: '+678',
  code: 'VU',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: '7s2x3BTSHceyJmYCq',
  name: 'Iran, Islamic Republic of',
  dialCode: '+98',
  code: 'IR',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: '7yW6rXBmsnyMJRgpg',
  name: 'Jamaica',
  dialCode: '+1 876',
  code: 'JM',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: '8CLS7AbaPf5aqFotj',
  name: 'Sao Tome and Principe',
  dialCode: '+239',
  code: 'ST',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: '8eJmoSA2Nmi4k7yoN',
  name: 'Colombia',
  dialCode: '+57',
  code: 'CO',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: '8GmCkfFN436vmg5CG',
  name: 'Viet Nam',
  dialCode: '+84',
  code: 'VN',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: '8u5kWXq8GXMHjvJrr',
  name: 'Pitcairn',
  dialCode: '+872',
  code: 'PN',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: '8ZB63oNPF4YRzoCbT',
  name: 'Aruba',
  dialCode: '+297',
  code: 'AW',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: '9cxQXFszNNkyeEuyY',
  name: 'United Kingdom',
  dialCode: '+44',
  code: 'GB',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: '9e6Bfy8kZv6qasQsu',
  name: 'Brunei Darussalam',
  dialCode: '+673',
  code: 'BN',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: '9PQzK69Ex2KsWDcsG',
  name: 'Micronesia, Federated States of',
  dialCode: '+691',
  code: 'FM',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: '9sTR6kkMtF8tsk9sd',
  name: 'Slovakia',
  dialCode: '+421',
  code: 'SK',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'acKcrXXSzegeh9Gqm',
  name: 'Morocco',
  dialCode: '+212',
  code: 'MA',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'ANvTunuFuEx5GXHqi',
  name: 'Montserrat',
  dialCode: '+1664',
  code: 'MS',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'aqiRmG8QM8LNLBztk',
  name: 'Palau',
  dialCode: '+680',
  code: 'PW',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'AQnGmhRfwzdbwcebE',
  name: 'Malaysia',
  dialCode: '+60',
  code: 'MY',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'Bd8rsmtdJXpeYnrSf',
  name: 'Belize',
  dialCode: '+501',
  code: 'BZ',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'BDFGeFapJGHYwXfqT',
  name: 'Falkland Islands (Malvinas)',
  dialCode: '+500',
  code: 'FK',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'bGephFcrgHKz4Z7CY',
  name: 'Myanmar',
  dialCode: '+95',
  code: 'MM',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'BGZK2k6js7ZhgE4MH',
  name: 'Russia',
  dialCode: '+7',
  code: 'RU',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'bmmw4LZTFZMKAr4pp',
  name: 'Mauritius',
  dialCode: '+230',
  code: 'MU',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'BMuuj2iZfpYeWh3J6',
  name: 'Réunion',
  dialCode: '+262',
  code: 'RE',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'bpeErEcMzP36mP45D',
  name: 'Honduras',
  dialCode: '+504',
  code: 'HN',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'bSEjcgmdfwNa8nS8z',
  name: 'Brazil',
  dialCode: '+55',
  code: 'BR',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'bYT8LcGtmNtKwHjLk',
  name: 'India',
  dialCode: '+91',
  code: 'IN',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'c2v5myABFaHnkAbbF',
  name: 'Macao',
  dialCode: '+853',
  code: 'MO',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'c5nebSH8EfrnpTXFL',
  name: 'Finland',
  dialCode: '+358',
  code: 'FI',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'CArG4ob9og2ZYzHc3',
  name: 'Belarus',
  dialCode: '+375',
  code: 'BY',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'CHc6pXQSfSmADcskS',
  name: 'Nigeria',
  dialCode: '+234',
  code: 'NG',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'ChZfSDakkAf8w6rQx',
  name: 'Nicaragua',
  dialCode: '+505',
  code: 'NI',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'ck9wYqYtBfMBL8vWR',
  name: 'El Salvador',
  dialCode: '+503',
  code: 'SV',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'cRbRCigN6w9N6zzC5',
  name: 'Portugal',
  dialCode: '+351',
  code: 'PT',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'CuJ3iAKSysBq6jY3j',
  name: 'Virgin Islands, U.S.',
  dialCode: '+1 340',
  code: 'VI',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'CwwNXf87csLE6Au95',
  name: 'Nauru',
  dialCode: '+674',
  code: 'NR',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'CXSPPPLxKoszSpyaL',
  name: 'Macedonia, The Former Yugoslav Republic of',
  dialCode: '+389',
  code: 'MK',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'cyv4CqdGo3xFpj7Pm',
  name: 'Sierra Leone',
  dialCode: '+232',
  code: 'SL',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'cZmgeetD74JykchRh',
  name: 'Norfolk Island',
  dialCode: '+672',
  code: 'NF',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'd5r5R4GXk9MTHhpgz',
  name: 'Bhutan',
  dialCode: '+975',
  code: 'BT',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'dg2cgf7TqscNZH2ZL',
  name: 'Bermuda',
  dialCode: '+1 441',
  code: 'BM',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'dhgqprfhrFcxcaa3q',
  name: 'Cape Verde',
  dialCode: '+238',
  code: 'CV',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'dTW6nvznEYzBnvMPP',
  name: 'Costa Rica',
  dialCode: '+506',
  code: 'CR',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'DvBdRHsEqbFnG3wjp',
  name: 'Papua New Guinea',
  dialCode: '+675',
  code: 'PG',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'DvEZmpbNaHP7nvqpx',
  name: 'Chile',
  dialCode: '+56',
  code: 'CL',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'EBzKb4A83QJR9n5qv',
  name: 'Virgin Islands, British',
  dialCode: '+1 284',
  code: 'VG',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'EdvTe3AC8D9gNGzqu',
  name: 'Cook Islands',
  dialCode: '+682',
  code: 'CK',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'EJPerTt58kmL69nWP',
  name: 'Mongolia',
  dialCode: '+976',
  code: 'MN',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'EnMRCxoyxFDYEbWib',
  name: 'Zambia',
  dialCode: '+260',
  code: 'ZM',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'EpFmi92yajKABvNKj',
  name: 'Netherlands Antilles',
  dialCode: '+599',
  code: 'AN',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'Et6vfzsZkREYGqyd3',
  name: 'Marshall Islands',
  dialCode: '+692',
  code: 'MH',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'ewWLjLfhY85EpRm2b',
  name: 'Paraguay',
  dialCode: '+595',
  code: 'PY',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'FCaPSph7J5CXifCmh',
  name: 'Mayotte',
  dialCode: '+262',
  code: 'YT',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'FEasf3DG8nADCAPFL',
  name: 'Gibraltar',
  dialCode: '+350',
  code: 'GI',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'fG7pL5Wn8W6yW7RgF',
  name: 'Kiribati',
  dialCode: '+686',
  code: 'KI',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'FGdySLsg7fXnPE3Gr',
  name: 'Saint Kitts and Nevis',
  dialCode: '+1 869',
  code: 'KN',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'Fi5LJy25bFiW54LwS',
  name: 'Andorra',
  dialCode: '+376',
  code: 'AD',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'fKWTmebZ35gu77xQt',
  name: 'New Caledonia',
  dialCode: '+687',
  code: 'NC',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'fowCRrA7CJxzzDyZw',
  name: 'Tokelau',
  dialCode: '+690',
  code: 'TK',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'FPNQfgRo3ayFkB6Zk',
  name: 'Grenada',
  dialCode: '+1 473',
  code: 'GD',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'fT9bugTa4XvZmZju4',
  name: 'British Indian Ocean Territory',
  dialCode: '+246',
  code: 'IO',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'fusphCuxQNXCAuzyr',
  name: 'Christmas Island',
  dialCode: '+61',
  code: 'CX',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'fXp9qFSLe95LpS3WK',
  name: 'San Marino',
  dialCode: '+378',
  code: 'SM',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'FxXu8Bgo8LKJ9Ccht',
  name: 'Zimbabwe',
  dialCode: '+263',
  code: 'ZW',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'FZvyXynQzjtdnqr4L',
  name: 'French Polynesia',
  dialCode: '+689',
  code: 'PF',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'g3mLwdQnRRNY5aroQ',
  name: 'Kuwait',
  dialCode: '+965',
  code: 'KW',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'G6SR29N4JiDX93B8N',
  name: 'Ghana',
  dialCode: '+233',
  code: 'GH',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'G7bC7BDofbg395y6r',
  name: 'Comoros',
  dialCode: '+269',
  code: 'KM',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'G7EuzEtniWF9RZFtK',
  name: 'Solomon Islands',
  dialCode: '+677',
  code: 'SB',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'GCX5TAsffxcRcDTWZ',
  name: 'AmericanSamoa',
  dialCode: '+1 684',
  code: 'AS',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'Gg8CBiZabSkNoLA2w',
  name: 'Israel',
  dialCode: '+972',
  code: 'IL',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'GGNZR7yMGk38XPArX',
  name: 'Iceland',
  dialCode: '+354',
  code: 'IS',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'GhYus4NnYL3DYGQjT',
  name: 'Hungary',
  dialCode: '+36',
  code: 'HU',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'gsejCz7vDG8uKWbdY',
  name: 'Dominica',
  dialCode: '+1 767',
  code: 'DM',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'Gw2EDx2SZYniac8Hs',
  name: 'Rwanda',
  dialCode: '+250',
  code: 'RW',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'gWJawEj7KNqj3E7Gw',
  name: 'South Africa',
  dialCode: '+27',
  code: 'ZA',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'GwRqrWtvcwa8K8khF',
  name: 'Turkmenistan',
  dialCode: '+993',
  code: 'TM',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'H7diH6h4Aspz9huZB',
  name: 'Netherlands',
  dialCode: '+31',
  code: 'NL',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'hCrunWnYYM3i3qQs3',
  name: 'Switzerland',
  dialCode: '+41',
  code: 'CH',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'hkhPWJsevvozPHds5',
  name: 'Ecuador',
  dialCode: '+593',
  code: 'EC',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'HmKDGgeKsRZAJvN2y',
  name: 'Argentina',
  dialCode: '+54',
  code: 'AR',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'hpFkBiC8sDakeApm9',
  name: 'France',
  dialCode: '+33',
  code: 'FR',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'hpixBicHeYgQN6Cc6',
  name: 'Cayman Islands',
  dialCode: '+ 345',
  code: 'KY',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'HREirv7AqqSeaR3q9',
  name: 'Cocos (Keeling) Islands',
  dialCode: '+61',
  code: 'CC',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'HuT5R2qxL5XLsx8CF',
  name: 'Saint Vincent and the Grenadines',
  dialCode: '+1 784',
  code: 'VC',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'HwyqDaS9km28XqegR',
  name: 'Guadeloupe',
  dialCode: '+590',
  code: 'GP',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'Hy3jmbRkSqH5fTayG',
  name: 'Somalia',
  dialCode: '+252',
  code: 'SO',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'i29tZtPdkCooP7wRS',
  name: 'Oman',
  dialCode: '+968',
  code: 'OM',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'idc26mujQhuR2dH9j',
  name: 'Montenegro',
  dialCode: '+382',
  code: 'ME',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'iDeZeM8oxk7wPqKPd',
  name: 'Burundi',
  dialCode: '+257',
  code: 'BI',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'iLnhDZHcS7J7TBhqq',
  name: 'Sri Lanka',
  dialCode: '+94',
  code: 'LK',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'iPi2xsxRhS3q5MkQu',
  name: 'Mozambique',
  dialCode: '+258',
  code: 'MZ',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'iukS83GCwnkwkR6wq',
  name: 'Timor-Leste',
  dialCode: '+670',
  code: 'TL',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'j9hF62uKJsbBjbbKb',
  name: 'United Arab Emirates',
  dialCode: '+971',
  code: 'AE',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'J9uWvciY2Ru3fhdr8',
  name: 'China',
  dialCode: '+86',
  code: 'CN',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'JaTM7ijLf872TmQyW',
  name: 'Isle of Man',
  dialCode: '+44',
  code: 'IM',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'JbeSmp575j4sMHKaJ',
  name: 'Palestinian Territory, Occupied',
  dialCode: '+970',
  code: 'PS',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'JbqGepoHCbGtY4wRF',
  name: 'Burkina Faso',
  dialCode: '+226',
  code: 'BF',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'jKdtm4s8hsYAQp8cB',
  name: 'Antarctica',
  dialCode: null,
  code: 'AQ',
  region: 'Antarctica',
  regionSlug: 'antarctica',
  created: null,
  updated: null,
}, {
  id: 'jKtgBSXKmGF6QKZRa',
  name: 'Gabon',
  dialCode: '+241',
  code: 'GA',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'jnJPmohTcS45Xk2n7',
  name: 'Congo, The Democratic Republic of the',
  dialCode: '+243',
  code: 'CD',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'jq79cWLdbDAxor5nu',
  name: 'Jordan',
  dialCode: '+962',
  code: 'JO',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'jQCSe2dZMKLKdh5X6',
  name: 'Qatar',
  dialCode: '+974',
  code: 'QA',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'jush5fGaL6MjoiKj8',
  name: 'Holy See (Vatican City State)',
  dialCode: '+379',
  code: 'VA',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'JwE9iuqLrTzQDn4uo',
  name: 'Yemen',
  dialCode: '+967',
  code: 'YE',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'jWK47pkfGZyYacCPL',
  name: 'Fiji',
  dialCode: '+679',
  code: 'FJ',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'k8QwxjcozwocJBGvg',
  name: 'Liberia',
  dialCode: '+231',
  code: 'LR',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'k9nAqfAXZ9pay2Ysi',
  name: 'New Zealand',
  dialCode: '+64',
  code: 'NZ',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'KGd3NTvRPcLnvqP8m',
  name: 'Albania',
  dialCode: '+355',
  code: 'AL',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'KpbXDLEH6FGzebwg4',
  name: 'Bahrain',
  dialCode: '+973',
  code: 'BH',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'KPfBruR8fHPC8FkuW',
  name: 'Lebanon',
  dialCode: '+961',
  code: 'LB',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'kw7DnDQszjeqA8JKC',
  name: 'Togo',
  dialCode: '+228',
  code: 'TG',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'L2cYnN4itxHEDWiuK',
  name: 'Australia',
  dialCode: '+61',
  code: 'AU',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'L5DisP83eTuY5HDSg',
  name: 'Lithuania',
  dialCode: '+370',
  code: 'LT',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'LAcFoPmhZrigTnvSb',
  name: 'Ethiopia',
  dialCode: '+251',
  code: 'ET',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'LMeou2ukeqeixhQjX',
  name: 'Latvia',
  dialCode: '+371',
  code: 'LV',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'LpvkN2SkABXzkenAC',
  name: 'Uganda',
  dialCode: '+256',
  code: 'UG',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'LrapNcQQXsGXYdFmE',
  name: 'Chad',
  dialCode: '+235',
  code: 'TD',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'm29mvW5YCJBKYd8Bt',
  name: 'Trinidad and Tobago',
  dialCode: '+1 868',
  code: 'TT',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'm549QtHwse3a4rc34',
  name: 'Benin',
  dialCode: '+229',
  code: 'BJ',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'M6evEvWAibQvGi59K',
  name: 'Tajikistan',
  dialCode: '+992',
  code: 'TJ',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'mFMJ6KNKNyPhTGLPb',
  name: 'Sudan',
  dialCode: '+249',
  code: 'SD',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'mhD42DhwtapHBfibx',
  name: 'Antigua and Barbuda',
  dialCode: '+1268',
  code: 'AG',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'MhHfdyk3qFRYPwbEp',
  name: 'Mauritania',
  dialCode: '+222',
  code: 'MR',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'MJZKQMCtJ6yyGt3XK',
  name: 'Spain',
  dialCode: '+34',
  code: 'ES',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'Mm6eZ8QQXx5xYGiCG',
  name: 'Tuvalu',
  dialCode: '+688',
  code: 'TV',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'MRcKZGhwudaukcksS',
  name: 'Guatemala',
  dialCode: '+502',
  code: 'GT',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'Msk5Leqou7WSg8pxs',
  name: 'Puerto Rico',
  dialCode: '+1 939',
  code: 'PR',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'MTSgbmBX4GCzcPdcW',
  name: 'Algeria',
  dialCode: '+213',
  code: 'DZ',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'N92kLvwAexRbdDETx',
  name: 'Saint Barthélemy',
  dialCode: '+590',
  code: 'BL',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'NERQWsmNAe8rqmSjj',
  name: 'Svalbard and Jan Mayen',
  dialCode: '+47',
  code: 'SJ',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'NJtExfbgJBRNGxyfT',
  name: 'Syrian Arab Republic',
  dialCode: '+963',
  code: 'SY',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'nQG4GfGtjfickgGnY',
  name: 'Italy',
  dialCode: '+39',
  code: 'IT',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'NSr5DXTmDoX6SCdxZ',
  name: 'Canada',
  dialCode: '+1',
  code: 'CA',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'NWiWMkzsrdX8Z2Z7C',
  name: 'Cambodia',
  dialCode: '+855',
  code: 'KH',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'NYxCjmE94QD9PXCmf',
  name: 'Iraq',
  dialCode: '+964',
  code: 'IQ',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'nZbNZg3mAFaByeGQT',
  name: 'Greenland',
  dialCode: '+299',
  code: 'GL',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'oE4uAKf2X9DR288iK',
  name: 'Peru',
  dialCode: '+51',
  code: 'PE',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'of8CMDDkNCd7EfeGp',
  name: 'Niue',
  dialCode: '+683',
  code: 'NU',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'oJoWafssnMASHB3Qt',
  name: 'Tanzania, United Republic of',
  dialCode: '+255',
  code: 'TZ',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'ow77nu4FMS5w7EWjQ',
  name: 'Libyan Arab Jamahiriya',
  dialCode: '+218',
  code: 'LY',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'oWNYcsGGM3BhfBnPx',
  name: 'Kyrgyzstan',
  dialCode: '+996',
  code: 'KG',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'p7DCL8tuXkntbMN5k',
  name: 'Guinea',
  dialCode: '+224',
  code: 'GN',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'p9GW7GBcaTPQ9Ktuw',
  name: 'Turks and Caicos Islands',
  dialCode: '+1 649',
  code: 'TC',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'pRRtB2yqeTk9banNH',
  name: 'Croatia',
  dialCode: '+385',
  code: 'HR',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'PRxCbQeXRknCzQDvC',
  name: 'Georgia',
  dialCode: '+995',
  code: 'GE',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'PweR6ZMjRo5jZg5dM',
  name: 'Turkey',
  dialCode: '+90',
  code: 'TR',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'pZKAswkimYbXaQNzM',
  name: 'Lesotho',
  dialCode: '+266',
  code: 'LS',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'PzQub3HTZ7TGC5vPo',
  name: 'Tunisia',
  dialCode: '+216',
  code: 'TN',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'Pzqwnfaf4QQmbD5AN',
  name: 'Niger',
  dialCode: '+227',
  code: 'NE',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'qcbKc9KKLpvMrFBai',
  name: 'Saint Martin',
  dialCode: '+590',
  code: 'MF',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'QdpEkxFSG6CpsfqN8',
  name: 'Jersey',
  dialCode: '+44',
  code: 'JE',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'qeb2KSYg84S5vk97z',
  name: 'Cameroon',
  dialCode: '+237',
  code: 'CM',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'qGqJgrYGZzegttQS8',
  name: 'Barbados',
  dialCode: '+1 246',
  code: 'BB',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'qJum37ShYAMNXx74u',
  name: 'Saudi Arabia',
  dialCode: '+966',
  code: 'SA',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'qLooNgivS9TwEWmmB',
  name: 'Greece',
  dialCode: '+30',
  code: 'GR',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'QsBfAvEGRGbK2NaMG',
  name: 'Korea, Republic of',
  dialCode: '+82',
  code: 'KR',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'qvneJHumRkTeAsArE',
  name: 'Hong Kong',
  dialCode: '+852',
  code: 'HK',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'QwonaoByf8taPQTpE',
  name: 'Philippines',
  dialCode: '+63',
  code: 'PH',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'qWXrZQHWPfsRbjdLv',
  name: 'Austria',
  dialCode: '+43',
  code: 'AT',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'qyGu3YhfKy6YNnRrd',
  name: 'Suriname',
  dialCode: '+597',
  code: 'SR',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'qzCWvNDaHutLKuufr',
  name: 'Singapore',
  dialCode: '+65',
  code: 'SG',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'r3hsYErMvQfaDsd2x',
  name: 'Czech Republic',
  dialCode: '+420',
  code: 'CZ',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'r97veJy9ttxnoAcYp',
  name: 'Norway',
  dialCode: '+47',
  code: 'NO',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'RDgypuwbixEamERAo',
  name: 'Panama',
  dialCode: '+507',
  code: 'PA',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'RdqsG6Eaii8ptQ3GD',
  name: 'Bahamas',
  dialCode: '+1 242',
  code: 'BS',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'RHMucEnfJA2rQxrEF',
  name: 'Bangladesh',
  dialCode: '+880',
  code: 'BD',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'rSjd5vxiZXwLm2J7T',
  name: 'Romania',
  dialCode: '+40',
  code: 'RO',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'Rw7Jaxjg84WTYnAnb',
  name: 'Bolivia, Plurinational State of',
  dialCode: '+591',
  code: 'BO',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'SdXP3XoRxcaWfvEby',
  name: 'Equatorial Guinea',
  dialCode: '+240',
  code: 'GQ',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'SfDYHBFTb8mNbeCqy',
  name: 'Estonia',
  dialCode: '+372',
  code: 'EE',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'sGGZebvKb5jjWBPHj',
  name: 'Wallis and Futuna',
  dialCode: '+681',
  code: 'WF',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'sh7fJWRP3eNQT4aMh',
  name: 'Tonga',
  dialCode: '+676',
  code: 'TO',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'SKswgB2qdaevuimdM',
  name: 'Bulgaria',
  dialCode: '+359',
  code: 'BG',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'spB75Axmphu2QzM4G',
  name: 'Guinea-Bissau',
  dialCode: '+245',
  code: 'GW',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'SpPDZTZDXdo4XaTD5',
  name: 'Guernsey',
  dialCode: '+44',
  code: 'GG',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'sPwQA3J9euDXsPPqQ',
  name: 'Congo',
  dialCode: '+242',
  code: 'CG',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'SSxZCq959jW2DhbFW',
  name: 'Saint Helena, Ascension and Tristan Da Cunha',
  dialCode: '+290',
  code: 'SH',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'stkwtsXpvGxMRmC6x',
  name: 'Uzbekistan',
  dialCode: '+998',
  code: 'UZ',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 't3rwAt3XPiS8KXtcc',
  name: 'Dominican Republic',
  dialCode: '+1 849',
  code: 'DO',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'T8bLZunuAqpnJY9HT',
  name: 'Belgium',
  dialCode: '+32',
  code: 'BE',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'TfRL8tuA2GbtPTmyd',
  name: 'Moldova, Republic of',
  dialCode: '+373',
  code: 'MD',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'tkrdNNDyX4pxHican',
  name: 'Gambia',
  dialCode: '+220',
  code: 'GM',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'uaAdkHvM32ahvt7Yw',
  name: 'Swaziland',
  dialCode: '+268',
  code: 'SZ',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'uhd5EE2b89gNp4v99',
  name: 'Cuba',
  dialCode: '+53',
  code: 'CU',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'ukxSKe7H36QbGrFHZ',
  name: 'Djibouti',
  dialCode: '+253',
  code: 'DJ',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'upS7Bg6j26gQwwg4E',
  name: 'Luxembourg',
  dialCode: '+352',
  code: 'LU',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'v7W3vraXtBEzfyi2f',
  name: 'Haiti',
  dialCode: '+509',
  code: 'HT',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'v9vFvTo544qP5cMjQ',
  name: 'Eritrea',
  dialCode: '+291',
  code: 'ER',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'vMjj5iJtHigF8QXKB',
  name: 'Sweden',
  dialCode: '+46',
  code: 'SE',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'W22CR3TjyWrC8FD6c',
  name: 'Saint Pierre and Miquelon',
  dialCode: '+508',
  code: 'PM',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'WAwntk845HKwuZR36',
  name: 'Israel',
  dialCode: '+972',
  code: 'IL',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'wBwbyfTG8aQmZHYxW',
  name: 'Cote d\'Ivoire',
  dialCode: '+225',
  code: 'CI',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'wDg3br6GHyhFf22dC',
  name: 'Slovenia',
  dialCode: '+386',
  code: 'SI',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'wLfcckpW7f3vTYCcs',
  name: 'Guam',
  dialCode: '+1 671',
  code: 'GU',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'WMB7cPCG8EwPjoPcq',
  name: 'Lao People\'s Democratic Republic',
  dialCode: '+856',
  code: 'LA',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'wNSLqA95tAaxu5GWR',
  name: 'Kazakhstan',
  dialCode: '+7 7',
  code: 'KZ',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'wRRb8hcZhHxrHS6rc',
  name: 'Malta',
  dialCode: '+356',
  code: 'MT',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'WtD5uhgngzEYjhXFs',
  name: 'Senegal',
  dialCode: '+221',
  code: 'SN',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'wu7EnT4WtdhFnN56S',
  name: 'United States',
  dialCode: '+1',
  code: 'US',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'wW5CZm9ADGMruEfFm',
  name: 'Serbia',
  dialCode: '+381',
  code: 'RS',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'WwuJTNAfLCEy7NnZJ',
  name: 'Afghanistan',
  dialCode: '+94',
  code: 'AF',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'WxuY6tMYoRbaNxXis',
  name: 'Ireland',
  dialCode: '+353',
  code: 'IE',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'WZjYwTMRxKjLc5Wjw',
  name: 'Venezuela, Bolivarian Republic of',
  dialCode: '+58',
  code: 'VE',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'X2LwQNozkdvkt64fF',
  name: 'Thailand',
  dialCode: '+66',
  code: 'TH',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'x65NbtXXtgiLZAmPp',
  name: 'Mali',
  dialCode: '+223',
  code: 'ML',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'xBbn7e9hYd74KFyxt',
  name: 'Kenya',
  dialCode: '+254',
  code: 'KE',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'XCiM4MZvRijDpf4JP',
  name: 'Anguilla',
  dialCode: '+1 264',
  code: 'AI',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'xDTrdiZoNpHaXWpqt',
  name: 'Denmark',
  dialCode: '+45',
  code: 'DK',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'xGXq9ZafiDRyZwFNb',
  name: 'Guyana',
  dialCode: '+595',
  code: 'GY',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'XiuWP9jbvphRaje4m',
  name: 'Saint Lucia',
  dialCode: '+1 758',
  code: 'LC',
  region: 'North America',
  regionSlug: 'north-america',
  created: null,
  updated: null,
}, {
  id: 'xk2bsDDcCGAXFqDBF',
  name: 'Liechtenstein',
  dialCode: '+423',
  code: 'LI',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'XPfCi6gtFGFs8ZPGu',
  name: 'South Georgia and the South Sandwich Islands',
  dialCode: '+500',
  code: 'GS',
  region: 'Antarctic',
  regionSlug: 'antarctic',
  created: null,
  updated: null,
}, {
  id: 'xqkywkpsocZYjvRpq',
  name: 'Central African Republic',
  dialCode: '+236',
  code: 'CF',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'xsEefyjE4ZEJ4GnjH',
  name: 'Northern Mariana Islands',
  dialCode: '+1 670',
  code: 'MP',
  region: 'Oceania',
  regionSlug: 'oceania',
  created: null,
  updated: null,
}, {
  id: 'xunFvGdYkRqE6ka2M',
  name: 'Botswana',
  dialCode: '+267',
  code: 'BW',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'y7t3TskZdd462YEYX',
  name: 'Uruguay',
  dialCode: '+598',
  code: 'UY',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'yA9bp2EkmuCXS3L2M',
  name: 'Nepal',
  dialCode: '+977',
  code: 'NP',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'yiht99mKerEapuTLz',
  name: 'French Guiana',
  dialCode: '+594',
  code: 'GF',
  region: 'South America',
  regionSlug: 'south-america',
  created: null,
  updated: null,
}, {
  id: 'yJAHnjJbYmWuKtvPq',
  name: 'Monaco',
  dialCode: '+377',
  code: 'MC',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'yvMpCfDHjGpHsK248',
  name: 'Malawi',
  dialCode: '+265',
  code: 'MW',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'YZEX8GB9QfTpD6JQn',
  name: 'Taiwan, Province of China',
  dialCode: '+886',
  code: 'TW',
  region: 'Asia',
  regionSlug: 'asia',
  created: null,
  updated: null,
}, {
  id: 'Z87oMo6MLfqwwW6qR',
  name: 'Germany',
  dialCode: '+49',
  code: 'DE',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'ZCPLEoh7GjhxzWK4y',
  name: 'Namibia',
  dialCode: '+264',
  code: 'NA',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}, {
  id: 'ZHs4CvZ6srfL3rasA',
  name: 'Azerbaijan',
  dialCode: '+994',
  code: 'AZ',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'ZpPd2Dtev7DXswAdR',
  name: 'Armenia',
  dialCode: '+374',
  code: 'AM',
  region: 'Europe',
  regionSlug: 'europe',
  created: null,
  updated: null,
}, {
  id: 'zuYdZAjnodWebPSEL',
  name: 'Angola',
  dialCode: '+244',
  code: 'AO',
  region: 'Africa',
  regionSlug: 'africa',
  created: null,
  updated: null,
}];

export default Countries;
