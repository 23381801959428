import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const SET_BOOTHS = 'modules/data/booths/SET_BOOTHS';
const UPDATE_BOOTH = 'modules/data/booths/UPDATE_BOOTH';

const initialState = fromJS([]);

const reducer = handleActions({
  [SET_BOOTHS]: (state, action = {}) => fromJS(action.payload || []),
  [UPDATE_BOOTH]: (state, action = {}) => {
    const { key, data = {} } = action.payload || {};
    return state.update(state.findIndex((item) => item.get(key) === _.get(data, key)),
      (item) => item.merge(data));
  },
}, initialState);

export const setBoothsAction = createAction(SET_BOOTHS, (data) => (data));
export const updateBoothAction = createAction(UPDATE_BOOTH, (key, data) => ({ key, data }));

export const getBoothsAsync = (conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.get('/booths/', { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(setBoothsAction(resp));

    return resp;
  };
};

export const updateBoothAsync = (id, data, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    let resp = await apiAuth.patch(`/booths/${id}/`, data, { params: { conference } });
    resp = _.get(resp, 'data');
    dispatch(updateBoothAction('id', resp));

    return resp;
  };
};

export const reserveBoothAsync = (id, data, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    await apiAuth.post(`/internal/booths/${id}/reserve/`, {}, { params: { conference } });
    dispatch(updateBoothAction('id', {
      id: _.toInteger(id),
      ...data,
    }));

    return {};
  };
};

export const makeBoothAvailableAsync = (id, data, conference) => {
  return async (dispatch, getState, { apiAuth }) => {
    await apiAuth.post(`/internal/booths/${id}/make_available/`, {}, { params: { conference } });
    dispatch(updateBoothAction('id', {
      id: _.toInteger(id),
      ...data,
    }));

    return {};
  };
};

export default reducer;
