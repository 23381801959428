import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';
import { fromJS } from 'immutable';
import thunk from 'redux-thunk';

import createRootReducer from './reducers';
import api, { apiAuth, apiGateway } from './http-client';

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_NAME,
});

export default function configureStore(preloadedState = {}) {
  const store = createStore(
    createRootReducer(history),
    fromJS(preloadedState),
    compose(
      applyMiddleware(
        thunk.withExtraArgument({ api, apiAuth, apiGateway }),
        routerMiddleware(history),
      ),
      /* eslint-disable no-underscore-dangle */
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
      /* eslint-enable */
    ),
  );

  return store;
}
