import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const SET_FORM = 'modules/ui/mail/SET_FORM';
const SET_LOADING = 'modules/ui/mail/SET_LOADING';
const SET_TEMPLATE = 'modules/ui/mail/SET_TEMPLATE';

const initialState = fromJS({
  edit: false,
  loading: false,
  form: {},
  template: '',
});

const reducer = handleActions({
  [SET_FORM]: (state, action = {}) => state.set('form', fromJS(action.payload)),
  [SET_LOADING]: (state, action = {}) => state.set('loading', action.payload),
  [SET_TEMPLATE]: (state, action = {}) => state.set('template', action.payload),
}, initialState);

export const setFormAction = createAction(SET_FORM, (form) => (form));
export const setLoadingAction = createAction(SET_LOADING, (loading) => (loading));
export const setTemplateAction = createAction(SET_TEMPLATE, (template) => (template));

export default reducer;
